import { TipoRefeicao } from 'src/app/shared/models/tipoRefeicao';
import { ClasseTipoPreparacaoPlanejamento } from './classeTipoPreparacaoPlanejamento';
import { DataPlanejamento } from './dataPlanejamento';
import { Planejamento } from 'src/app/shared/models/planejamento';
import { FichaTecnica } from 'src/app/shared/models/fichaTecnica';
import { ResumoNutrientes } from 'src/app/shared/models/resumoNutrientes';

export class TipoRefeicaoPlanejamento extends TipoRefeicao {
  classeTipoPreparacaoPlanejamento: ClasseTipoPreparacaoPlanejamento[];
  dataPlanejamento: DataPlanejamento[];


  planejamento: Planejamento[];
  listaFichaTecnica: FichaTecnica[];
  resumoNutrientes: ResumoNutrientes;
}

