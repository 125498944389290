import { UsuarioEmpresa } from './usuarioEmpresa';

export class Usuario {
  id: string;
  nome: string;
  sobrenome: string;
  foto: string;
  email: string;
  senha: string;
  phoneNumber: string;
  urlFoto: string;
  senhaConfirmacao: string;
  usuarioEmpresa: UsuarioEmpresa[];
  tipoUsuario: number;
  permissoes: Object;
  superAdmin: boolean;

  perfilId: string;
  perfilNome: string;
}
