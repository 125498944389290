import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Endereco } from '../../shared/models/endereco';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ConsultaCepService {

  constructor(
    private http: HttpClient,
    private toastr: ToastrService
  ) { }

  apiURL = 'https://viacep.com.br/ws/';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  getEnderecoCep(cep: string): Observable<Endereco[]> {
    return this.http.get<Endereco[]>(this.apiURL + cep + '/json/')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  handleError(error: { error: any; }) {
    const errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      this.toastr.warning(`Cep Não encontrado`);
    }
    return throwError(errorMessage);
  }

}
