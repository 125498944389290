import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Usuario } from 'src/app/shared/models/usuario';
import { UsuarioEmpresa } from 'src/app/shared/models/usuarioEmpresa';
import { CadastrarCliente } from 'src/app/shared/models/cadastrarCliente';
import { Auth } from 'src/app/shared/models/auth';


@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  login(email: string, senha: string): Observable<any> {
    return this.http.post<any>(this.urlService + 'usuario/login', { email, senha }, super.ObterHeaderJson());
  }

  cadastrar(usuario: Usuario): Observable<Usuario> {
    return this.http.post<Usuario>(this.urlService + 'usuario/cadastrar', usuario, super.ObterHeaderJson());
  }

  confirmacaoCadastro(dadosConfirmacaoCadastro: any): Observable<Usuario> {
    return this.http.post<Usuario>(this.urlService + 'usuario/confirmacaoCadastro', dadosConfirmacaoCadastro, super.ObterHeaderJson());
  }

  alterar(usuario: Usuario): Observable<any> {
    return this.http.post<any>(this.urlService + 'usuario/alterar', usuario, super.ObterHeaderJson());
  }

  reenviarConfirmacaoCadastro(email: string): Observable<Usuario> {
    return this.http.post<Usuario>(this.urlService + 'usuario/reenviarConfirmacaoCadastro?email=' + email, super.ObterHeaderJson());
  }

  alterarSenha(usuario: any): Observable<Usuario> {
    return this.http.post<Usuario>(this.urlService + 'usuario/alterarSenha', usuario, super.ObterHeaderJson());
  }

  recuperarSenha(email: string): Observable<any> {
    return this.http.post<any>(this.urlService + 'usuario/recuperarSenha?email=' + email, super.ObterHeaderJson());
  }

  logout(): Observable<any> {
    return this.http.post<any>(this.urlService + 'usuario/logout', super.ObterHeaderJson());
  }

  tokenPermissoesEmpresa(usuarioId, usuarioEmpresaId): Observable<any> {
    return this.http.get<any>(`${this.urlService}usuario/TokenPermissoesEmpresa?usuarioId=${usuarioId}&usuarioEmpresaId=${usuarioEmpresaId}`, super.ObterHeaderJson());
  }

  alterarEmpresaPrincipal(usuarioEmpresa: UsuarioEmpresa): Observable<UsuarioEmpresa[]> {
    return this.http.post<UsuarioEmpresa[]>(this.urlService + 'usuario/alterarEmpresaPrincipal', usuarioEmpresa, super.ObterAuthHeaderJson());
  }

  obterPorId(id: string): Observable<Usuario> {
    return this.http.get<Usuario>(this.urlService + 'usuario/obterPeloId?id=' + id, super.ObterAuthHeaderJson());
  }

  obterPeloEmail(email: string): Observable<Usuario> {
    return this.http.get<Usuario>(this.urlService + 'usuario/obterPeloEmail?email=' + email, super.ObterAuthHeaderJson());
  }

  gerarLinkRegistrarClienteResponsavel(usuario: Usuario): Observable<string> {
    return this.http.post<string>(`${this.urlService}usuario/gerarLinkRegistrarClienteResponsavel`, usuario, super.ObterHeaderJson());
  }

  cadastrarUsuarioCliente(cadastrarCliente: CadastrarCliente): Observable<Usuario> {
    return this.http.post<Usuario>(this.urlService + 'usuario/cadastrarUsuarioCliente', cadastrarCliente, super.ObterAuthHeaderJson());
  }

  refreshToken(): Observable<Auth> {
    return this.http.get<Auth>(`${this.urlService}usuario/refreshToken`, super.ObterAuthHeaderJson());
  }
  
}
