import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { CustomCurrencyMaskConfig } from './currency-mask-config/currency-mask-config';

import { DropzoneModule, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfig } from './dropzone-config/dropzone-config';


// Módulos
import { NgxbootstrapModule } from './ngxbootstrap/ngxbootstrap.module';
import { BlockUIModule } from 'ng-block-ui';
import { CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorGithubModule } from 'ngx-color/github';
import { ColorCircleModule } from 'ngx-color/circle';
import { TagInputModule } from 'ngx-chips';
import { PipesModule } from './pipes/pipes.module';
import {NgxMaskModule} from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { FileSaverModule } from 'ngx-filesaver';
import {DpDatePickerModule} from 'ng2-date-picker';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxTreeDndModule } from 'ngx-tree-dnd';

// Componentes Compartilhados
import { InputComponent } from './input/input.component';
import { BlockUiTemplateComponent } from './block-ui-template/block-ui-template.component';
import { TableComponent } from './table/table.component';
import * as moment from 'moment';



import { ModalExcluirItemComponent } from './modal-excluir-item/modal-excluir-item.component';
import { ImagemComponent } from './imagem/imagem.component';

// Serviços
import { LoginService } from '../core/services/login.service';
import { ConsultaCepService } from '../core/services/consulta-cep.service';
import { DatapickerComponent } from './datapicker/datapicker.component';
import { DatapickerRangeComponent } from './datapicker-range/datapicker-range.component';
import { CadastroRapidoComponent } from './cadastro-rapido/cadastro-rapido.component';
import { CurrencyComponent } from './currency/currency.component';
import { NomeReduzidoComponent } from './nome-reduzido/nome-reduzido.component';
import { RouterModule } from '@angular/router';
import { DaterangepickerInlineComponent } from './daterangepicker-inline/daterangepicker-inline.component';
import { DataComponent } from './data/data.component';
import { ModalBloqueioPlanoService } from './modal-bloqueio-plano/modal-bloqueio-plano.service';
import { ImprimirRelatorioComprasComponent } from './imprimir-relatorio-compras/imprimir-relatorio-compras.component';
import { DataFilterComponent } from './data-filter/data-filter.component';
import { AuthGuard } from '../core/authentication/auth.guard';
import { ColorPickerModule } from 'ngx-color-picker';

moment.locale('pt-br');

@NgModule({
  declarations: [
    InputComponent,
    BlockUiTemplateComponent,
    TableComponent,
    ModalExcluirItemComponent,
    ImagemComponent,
    DatapickerComponent,
    DatapickerRangeComponent,
    CadastroRapidoComponent,
    CurrencyComponent,
    NomeReduzidoComponent,
    DaterangepickerInlineComponent,
    DataComponent,
    ImprimirRelatorioComprasComponent,
    DataFilterComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    NgxbootstrapModule,
    CurrencyMaskModule,
    PipesModule,
    NgSelectModule,
    ColorGithubModule,
    ColorCircleModule,
    DropzoneModule,
    TagInputModule,
    FileSaverModule,
    UiSwitchModule,
    NgxChartsModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    BlockUIModule.forRoot({
      template: BlockUiTemplateComponent
    }),
    DpDatePickerModule,
    NgxTreeDndModule
  ],
  exports: [
    InputComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    NgxbootstrapModule,
    BlockUIModule,
    TableComponent,
    ModalExcluirItemComponent,
    CurrencyMaskModule,
    PipesModule,
    NgSelectModule,
    ColorGithubModule,
    ColorCircleModule,
    DropzoneModule,
    TagInputModule,
    ImagemComponent,
    DatapickerComponent,
    DatapickerRangeComponent,
    NgxMaskModule,
    ToastrModule,
    CadastroRapidoComponent,
    FileSaverModule,
    DpDatePickerModule,
    CurrencyComponent,
    UiSwitchModule,
    NgxChartsModule,
    NomeReduzidoComponent,
    DaterangepickerInlineComponent,
    DataComponent,
    ImprimirRelatorioComprasComponent,
    NgxTreeDndModule,
    DataFilterComponent,
    ColorPickerModule,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AuthGuard,
        LoginService,
        ConsultaCepService,
        ModalBloqueioPlanoService,
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        { provide: DROPZONE_CONFIG, useValue: DropzoneConfig }
      ]
    };
  }
}
