import constants from './constants';

const pageMenuFinanceiro = [
  {
    icon: 'fa fa-dollar-sign',
    title: 'Financeiro',
    url: '',
    caret: true,
    submenu: [
      {
        url: 'financeiro/movimentacoes',
        title: 'Movimentações',
        moduloId: constants.MODULOS.FINANCEIRO_MOVIMENTACOES,
        moduloPlano: constants.modulosPlanos.moduloFinanceiroMovimentacoes
      },
      {
        url: 'financeiro/categorias',
        title: 'Categorias',
        moduloId: constants.MODULOS.FINANCEIRO_CATEGORIAS,
        moduloPlano: constants.modulosPlanos.moduloFinanceiroCategorias
      },
      {
        url: 'financeiro/centro-de-custo',
        title: 'Centros de custo',
        moduloId: constants.MODULOS.FINANCEIRO_CENTROS_DE_CUSTO,
        moduloPlano: constants.modulosPlanos.moduloFinanceiroCentroCusto
      },
    ]
  },
  {
    icon: 'fa fa-poll',
    title: 'Relatórios',
    url: '',
    caret: true,
    submenu: [
      {
        url: 'relatorios/fluxo-de-caixa',
        title: 'Custos',
        moduloId: constants.MODULOS.RELATORIOS,
        moduloPlano: constants.modulosPlanos.moduloFinanceiroMovimentacoes
      },
      {
        url: 'relatorios/demonstracao-resultado-exercicio',
        title: 'DRE',
        moduloId: constants.MODULOS.RELATORIOS,
        moduloPlano: constants.modulosPlanos.moduloFinanceiroMovimentacoes
      }
    ]
  },
  {
    icon: 'fa fa-users-cog',
    title: 'Painel Administrativo',
    url: '/painel-admin/gerenciar-empresa',
    caret: false,
    moduloId: constants.MODULOS.PAINEL_ADMIN,
  }
];


export default pageMenuFinanceiro;
