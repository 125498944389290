

import { Injectable } from '@angular/core';
import { ItemMovimentacao } from 'src/app/shared/models/itemMovimentacao';
import { MovimentacaoTransferenciaItem } from 'src/app/shared/models/movimentacaoTransferenciaItem';
import { NomeItemNotaFiscal } from 'src/app/shared/models/nomeItemNotaFiscal';
import { ItemOrdemSaida } from './ordem-saida/models/itemOrdemSaida';

@Injectable({
  providedIn: 'root'
})
export class EstoqueHelperService {

  nomeItemNotaFiscalUnidadeCompra(nomeItemNotaFiscal: NomeItemNotaFiscal, itemNotaFiscal: NomeItemNotaFiscal): string {
    let retorno = '';

    if (nomeItemNotaFiscal.unidadeCompraId != null) {
      const unidadeCompraSelecionada = itemNotaFiscal.alimento != null ?
        itemNotaFiscal.alimento.unidadeCompra.find(x => x.id === nomeItemNotaFiscal.unidadeCompraId) :
        itemNotaFiscal.materialDiverso.unidadeCompra.find(x => x.id === nomeItemNotaFiscal.unidadeCompraId);

      if (unidadeCompraSelecionada != null) {
        retorno = unidadeCompraSelecionada.nome;
      } else {
        if (itemNotaFiscal.alimento != null) {
          retorno = itemNotaFiscal.alimento.unidadeCompra.length > 0 ? itemNotaFiscal.alimento.unidadeCompra[0].nome : '';
        }

        if (itemNotaFiscal.materialDiverso != null) {
          retorno = itemNotaFiscal.materialDiverso.unidadeCompra.length > 0 ? itemNotaFiscal.materialDiverso.unidadeCompra[0].nome : '';
        }
      }
    }

    return retorno;
  }

  nomeUnidadeCompraSelecionada(itemMovimentacao: ItemMovimentacao): string {
    let retorno = '';

    if (itemMovimentacao.unidadeCompraId.length > 0) {
      const unidadeCompraSelecionada = itemMovimentacao.alimento != null ?
      itemMovimentacao.alimento.unidadeCompra.find(x => x.id === itemMovimentacao.unidadeCompraId) :
      itemMovimentacao.materialDiverso.unidadeCompra.find(x => x.id === itemMovimentacao.unidadeCompraId);

      if (unidadeCompraSelecionada != null) {
        retorno = unidadeCompraSelecionada.nome;
      } else {
        if (itemMovimentacao.alimento != null) {
          retorno = itemMovimentacao.alimento.unidadeCompra.length > 0 ? itemMovimentacao.alimento.unidadeCompra[0].nome : '';
        }

        if (itemMovimentacao.materialDiverso != null) {
          retorno = itemMovimentacao.materialDiverso.unidadeCompra.length > 0 ? itemMovimentacao.materialDiverso.unidadeCompra[0].nome : '';
        }
      }

    }
    return retorno;
  }

  calcularValorTotalItemMovimentacao(listaItemMovimentacao: ItemMovimentacao[]): number {
    let valorTotal = 0;
    listaItemMovimentacao?.forEach(item => {
      if (item.valorTotal !== 0 || item.valorTotal !== null) {
        valorTotal += item.valorTotal;
      }
    });
    return valorTotal;
  }

  calcularQuantidades(itemOrdemSaida: ItemOrdemSaida): void {
    this.calcularCampoQuantidadeEstoque(itemOrdemSaida);
    this.calcularCampoQuantidadePlanejada(itemOrdemSaida);

    itemOrdemSaida.quantidadeTotal = itemOrdemSaida.quantidadePlanejadaTemp;
  }

  calcularCampoQuantidadeEstoque(itemOrdemSaida: ItemOrdemSaida): number {
    itemOrdemSaida.quantidadeEstoque = this.calcularUnidadeCompraQuantidadeEstoque(itemOrdemSaida);
    return itemOrdemSaida.quantidadeEstoque;
  }

  calcularUnidadeCompraQuantidadeEstoque(itemOrdemSaida: ItemOrdemSaida): number {
    let retorno = 0;

    if (itemOrdemSaida.unidadeCompraId != null) {
      if (itemOrdemSaida.alimento) {
        const unidadeCompraSelecionada = itemOrdemSaida.alimento.unidadeCompra.find(x => x.id === itemOrdemSaida.unidadeCompraId);

        let quantidadeUnidadeCompra = 0;
        if (unidadeCompraSelecionada.unidadeQuantidadeReferencia != null) {
          if (unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'kg' || unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'L') {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia * 1000;
          } else {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
          }
        } else {
          quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
        }

        retorno = itemOrdemSaida.alimento.quantidadeEstoque / quantidadeUnidadeCompra;
      }
      if (itemOrdemSaida.materialDiverso) {
        const unidadeCompraSelecionada = itemOrdemSaida.materialDiverso.unidadeCompra.find(x => x.id === itemOrdemSaida.unidadeCompraId);

        let quantidadeUnidadeCompra = 0;
        if (unidadeCompraSelecionada.unidadeQuantidadeReferencia != null) {
          if (unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'kg' || unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'L') {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia * 1000;
          } else {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
          }
        } else {
          quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
        }

        retorno = itemOrdemSaida.materialDiverso.quantidadeEstoque / quantidadeUnidadeCompra;
      }
    }

    return retorno;
  }

  calcularCampoQuantidadePlanejada(itemOrdemSaida: ItemOrdemSaida): number {
    itemOrdemSaida.quantidadePlanejadaTemp = this.calcularUnidadeCompraQuantidadePlanejada(itemOrdemSaida);
    return itemOrdemSaida.quantidadePlanejadaTemp;
  }

  calcularUnidadeCompraQuantidadePlanejada(itemOrdemSaida: ItemOrdemSaida): number {
    let retorno = 0;

    if (itemOrdemSaida.unidadeCompraId != null) {
      if (itemOrdemSaida.alimento) {
        const unidadeCompraSelecionada = itemOrdemSaida.alimento.unidadeCompra.find(x => x.id === itemOrdemSaida.unidadeCompraId);

        let quantidadeUnidadeCompra = 0;
        if (unidadeCompraSelecionada.unidadeQuantidadeReferencia != null) {
          if (unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'kg' || unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'L') {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia * 1000;
          } else {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
          }
        } else {
          quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
        }

        retorno = itemOrdemSaida.quantidadePlanejada / quantidadeUnidadeCompra;
      }
      if (itemOrdemSaida.materialDiverso) {
        const unidadeCompraSelecionada = itemOrdemSaida.materialDiverso.unidadeCompra.find(x => x.id === itemOrdemSaida.unidadeCompraId);

        let quantidadeUnidadeCompra = 0;
        if (unidadeCompraSelecionada.unidadeQuantidadeReferencia != null) {
          if (unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'kg' || unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'L') {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia * 1000;
          } else {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
          }
        } else {
          quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
        }

        retorno = itemOrdemSaida.quantidadePlanejada / quantidadeUnidadeCompra;
      }
    }

    return retorno;
  }

  nomeItemMovimentacao(itemMovimentacao: ItemMovimentacao): string {
    let retorno = '';

    if (itemMovimentacao?.alimento) {
      retorno = itemMovimentacao?.alimento?.nome;
    }

    if (itemMovimentacao?.materialDiverso) {
      retorno = itemMovimentacao?.materialDiverso?.nome;
    }

    if (itemMovimentacao?.fichaTecnica) {
      retorno = itemMovimentacao?.fichaTecnica?.nome;
    }

    return retorno;
  }

  nomeMovimentacaoTransferenciaItem(movimentacaoTransferenciaItem: MovimentacaoTransferenciaItem): string {
    let retorno = '';

    if (movimentacaoTransferenciaItem?.alimento) {
      retorno = movimentacaoTransferenciaItem?.alimento?.nome;
    }

    if (movimentacaoTransferenciaItem?.materialDiverso) {
      retorno = movimentacaoTransferenciaItem?.materialDiverso?.nome;
    }

    if (movimentacaoTransferenciaItem?.fichaTecnica) {
      retorno = movimentacaoTransferenciaItem?.fichaTecnica?.nome;
    }

    return retorno;
  }

  calcularCustoFichaTecnica(itemMovimentacao: ItemMovimentacao): void {
    if (itemMovimentacao.fichaTecnica) {

      if (itemMovimentacao.unidadeQuantidadeTotal === 'porcao') {
        itemMovimentacao.valorTotal = itemMovimentacao.fichaTecnica.custoTotal * itemMovimentacao.quantidadeTotal;
      } else {
        let quantidadeTotalEmGramas = 0;
        let quantidadePorcaoEmGramas = 0;

        if (itemMovimentacao.unidadeQuantidadeTotal === 'L' || itemMovimentacao.unidadeQuantidadeTotal === 'kg') {
          quantidadeTotalEmGramas = itemMovimentacao.quantidadeTotal * 1000;
        } else {
          quantidadeTotalEmGramas = itemMovimentacao.quantidadeTotal;
        }

        if (itemMovimentacao.fichaTecnica.unidadeQuantidadePorcao === 'L' || itemMovimentacao.fichaTecnica.unidadeQuantidadePorcao === 'kg') {
          quantidadePorcaoEmGramas = itemMovimentacao.fichaTecnica.quantidadePorcao * 1000;
        } else {
          quantidadePorcaoEmGramas = itemMovimentacao.fichaTecnica.quantidadePorcao;
        }

        itemMovimentacao.valorTotal = quantidadeTotalEmGramas * itemMovimentacao.fichaTecnica.custoTotal / quantidadePorcaoEmGramas;
      }
    }
  }

  converterQuantidadeTotalPorUnidade(itemMovimentacao: ItemMovimentacao, unidade: string): void {
    if (itemMovimentacao.unidadeQuantidadeTotal === 'porcao') {
      itemMovimentacao.quantidadeTotal = 0;
      itemMovimentacao.valorTotal = 0;
    }

    if (unidade === 'porcao' && (itemMovimentacao.unidadeQuantidadeTotal === 'kg' ||
      itemMovimentacao.unidadeQuantidadeTotal === 'g')) {
        itemMovimentacao.valorTotal = 0;
    }

    itemMovimentacao.unidadeQuantidadeTotal = unidade;
    itemMovimentacao.quantidadeTotal = this.truncarValor(itemMovimentacao.quantidadeTotal);

    if (unidade === 'porcao') {
      itemMovimentacao.quantidadeTotal = 0;
      itemMovimentacao.unidadeQuantidadeTotal = unidade;
    }
  }

  truncarValor(valor: number): number {
    const valorTemp = valor * 100;
    return Math.trunc(valorTemp) / 100;
  }

  campoPreenchido(quantidadeTotal: number): boolean {
    return !quantidadeTotal || quantidadeTotal === 0;
  }

  verificarValorMaiorQueEstoque(itemMovimentacao: ItemMovimentacao): boolean {
    let retorno = false;
    let quantidadeEstoque = 0;
    let quantidadeTotal = 0;

    quantidadeTotal = this.calcularQuantidadeTotalItemMovimentacao(itemMovimentacao);

    if (itemMovimentacao.alimentoId) {
      quantidadeEstoque = itemMovimentacao.alimento.quantidadeEstoque;
      retorno = quantidadeEstoque < quantidadeTotal;
    }

    if (itemMovimentacao.materialDiversoId) {
      quantidadeEstoque = itemMovimentacao.materialDiverso.quantidadeEstoque;
      retorno = quantidadeEstoque < quantidadeTotal;
    }
    if (itemMovimentacao.fichaTecnicaId) {
      quantidadeEstoque = itemMovimentacao.fichaTecnica.quantidadeEstoque;
      retorno = quantidadeEstoque < quantidadeTotal;
    }

    return retorno;
  }

  verificarValorMaiorQueEstoqueMovimentacaoTransferenciaItem(movimentacaoTransferenciaItem: MovimentacaoTransferenciaItem): boolean {
    let retorno = false;
    let quantidadeEstoque = 0;
    let quantidadeTotal = 0;

    quantidadeTotal = this.calcularQuantidadeTotalMovimentacaoTransferenciaItem(movimentacaoTransferenciaItem);

    if (movimentacaoTransferenciaItem.alimentoId) {
      quantidadeEstoque = movimentacaoTransferenciaItem.alimento.quantidadeEstoque;
      retorno = quantidadeEstoque < quantidadeTotal;
    }

    if (movimentacaoTransferenciaItem.materialDiversoId) {
      quantidadeEstoque = movimentacaoTransferenciaItem.materialDiverso.quantidadeEstoque;
      retorno = quantidadeEstoque < quantidadeTotal;
    }
    if (movimentacaoTransferenciaItem.fichaTecnicaId) {
      quantidadeEstoque = movimentacaoTransferenciaItem.fichaTecnica.quantidadeEstoque;
      retorno = quantidadeEstoque < quantidadeTotal;
    }

    return retorno;
  }

  calcularQuantidadeTotalItemMovimentacao(itemMovimentacao: ItemMovimentacao): number {
    let retorno = 0;

    if (itemMovimentacao.unidadeCompraId != null) {
      if (itemMovimentacao.alimento) {
        const unidadeCompraSelecionada = itemMovimentacao.alimento.unidadeCompra.find(x => x.id === itemMovimentacao.unidadeCompraId);

        let quantidadeUnidadeCompra = 0;
        if (unidadeCompraSelecionada.unidadeQuantidadeReferencia != null) {
          if (unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'kg' || unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'L') {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia * 1000;
          } else {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
          }
        } else {
          quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
        }

        retorno = itemMovimentacao.quantidadeTotal * quantidadeUnidadeCompra;
      }

      if (itemMovimentacao.materialDiverso) {
        const unidadeCompraSelecionada = itemMovimentacao.materialDiverso.unidadeCompra.find(x => x.id === itemMovimentacao.unidadeCompraId);

        let quantidadeUnidadeCompra = 0;
        if (unidadeCompraSelecionada.unidadeQuantidadeReferencia != null) {
          if (unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'kg' || unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'L') {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia * 1000;
          } else {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
          }
        } else {
          quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
        }

        retorno = itemMovimentacao.quantidadeTotal * quantidadeUnidadeCompra;
      }
    }

    if (itemMovimentacao.fichaTecnica) {
      if (itemMovimentacao.unidadeQuantidadeTotal === 'kg' || itemMovimentacao.unidadeQuantidadeTotal === 'g') {
        retorno = this.converterParaGrama(itemMovimentacao.quantidadeTotal, itemMovimentacao.unidadeQuantidadeTotal);
      }

      if (itemMovimentacao.unidadeQuantidadeTotal === 'porcao') {
        if (itemMovimentacao.fichaTecnica) {
          retorno = this.converterParaGrama(itemMovimentacao.fichaTecnica.quantidadePorcao, itemMovimentacao.fichaTecnica.unidadeQuantidadePorcao) * itemMovimentacao.quantidadeTotal;
        }
      }
    }

    return retorno;
  }

  calcularQuantidadeTotalMovimentacaoTransferenciaItem(movimentacaoTransferenciaItem: MovimentacaoTransferenciaItem): number {
    let retorno = 0;

    if (movimentacaoTransferenciaItem.unidadeCompraId != null) {
      if (movimentacaoTransferenciaItem.alimento) {
        const unidadeCompraSelecionada = movimentacaoTransferenciaItem.alimento.unidadeCompra.find(x => x.id === movimentacaoTransferenciaItem.unidadeCompraId);

        let quantidadeUnidadeCompra = 0;
        if (unidadeCompraSelecionada.unidadeQuantidadeReferencia != null) {
          if (unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'kg' || unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'L') {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia * 1000;
          } else {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
          }
        } else {
          quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
        }

        retorno = movimentacaoTransferenciaItem.quantidadeTotal * quantidadeUnidadeCompra;
      }

      if (movimentacaoTransferenciaItem.materialDiverso) {
        const unidadeCompraSelecionada = movimentacaoTransferenciaItem.materialDiverso.unidadeCompra.find(x => x.id === movimentacaoTransferenciaItem.unidadeCompraId);

        let quantidadeUnidadeCompra = 0;
        if (unidadeCompraSelecionada.unidadeQuantidadeReferencia != null) {
          if (unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'kg' || unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'L') {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia * 1000;
          } else {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
          }
        } else {
          quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
        }

        retorno = movimentacaoTransferenciaItem.quantidadeTotal * quantidadeUnidadeCompra;
      }
    }

    if (movimentacaoTransferenciaItem.fichaTecnica) {
      if (movimentacaoTransferenciaItem.unidadeQuantidadeTotal === 'kg' || movimentacaoTransferenciaItem.unidadeQuantidadeTotal === 'g') {
        retorno = this.converterParaGrama(movimentacaoTransferenciaItem.quantidadeTotal, movimentacaoTransferenciaItem.unidadeQuantidadeTotal);
      }

      if (movimentacaoTransferenciaItem.unidadeQuantidadeTotal === 'porcao') {
        if (movimentacaoTransferenciaItem.fichaTecnica) {
          retorno = this.converterParaGrama(movimentacaoTransferenciaItem.fichaTecnica.quantidadePorcao, movimentacaoTransferenciaItem.fichaTecnica.unidadeQuantidadePorcao)
          * movimentacaoTransferenciaItem.quantidadeTotal;
        }
      }
    }

    return retorno;
  }

  converterParaGrama(item: number, unidadeItem: string): number {
    let response = unidadeItem === 'kg' || unidadeItem === 'L' ? (item * 1000) : item;
    return response;
  }

}
