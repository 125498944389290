export class FiltroUsuarioEmpresa {
  nome: string;
  empresaId: string;
  usuarioId: string;
  ativo: boolean | null;
  excluido: boolean | null;
  termo: string;
  tamanhoPagina: number;
  pagina: number;
}
