import { ItemOrdemSaida } from './itemOrdemSaida';
import { Motivo } from 'src/app/shared/models/motivo';
import { NotaFiscal } from 'src/app/shared/models/notaFiscal';


export class OrdemSaida {
  id: string;
  nome: string;
  dataCadastro: Date;
  dataAtualizacao: Date;
  tipo: boolean;
  qtdItens: number;
  empresaId: string;
  usuarioId: string;
  fornecedor: string;
  valor: number;
  status: boolean;
  planejamentoId: string;
  itemOrdemSaida: ItemOrdemSaida[];
  motivo: Motivo[];
  collapse: boolean;
  notaFiscal: NotaFiscal;
  dataPlanejamento: Date;
  ordemSaidaMapaProducaoNome: string;
  ordemSaidaServicoNome: string;
}
