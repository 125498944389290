import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(tap ((event: HttpEvent<any>) => {

      if (event instanceof HttpResponse) {
        // do stuff with response if you want

        // Verificando se o Token ainda está válido
        const expira = localStorage.getItem('rotulagem.expira');
        if (expira) {

          const dtExpira = moment(expira);
          if (moment().isAfter(dtExpira)) {
            this.authService.logout(true);

          }
        }
      }
    }, (err: any) => {

      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          // redirect to the login route
          this.authService.logout(false);
        }
      }
    }));
  }

}
