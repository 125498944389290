
<span *ngIf="alimentoId" [popover]="!popoverDisabled && nome.length > tamanhoNome ? nome : ''" triggers="mouseenter:mouseleave" [placement]="popoverPlacement">
  <a target="_blank" [routerLink]="['/alimentos/editar-alimento/', alimentoId]" [style.color]="cor">
    {{nomeReduzido(nome, tamanhoNome)}}
  </a>
</span>

<span *ngIf="fichaTecnicaId" [popover]="!popoverDisabled && nome.length > tamanhoNome ? nome : ''" triggers="mouseenter:mouseleave" [placement]="popoverPlacement">
  <a target="_blank" [routerLink]="['/fichas-tecnicas/editar-ficha-tecnica', fichaTecnicaId]" [style.color]="cor">
    {{nomeReduzido(nome, tamanhoNome)}}
  </a>
</span>

<span *ngIf="materialDiversoId" [popover]="!popoverDisabled && nome.length > tamanhoNome ? nome : ''" triggers="mouseenter:mouseleave" [placement]="popoverPlacement">
  <a target="_blank" [routerLink]="['/materiais-diversos/editar-material-diverso', materialDiversoId]" [style.color]="cor">
    {{nomeReduzido(nome, tamanhoNome)}}
  </a>
</span>
<span *ngIf="produtoId" [popover]="!popoverDisabled && nome.length > tamanhoNome ? nome : ''" triggers="mouseenter:mouseleave" [placement]="popoverPlacement">
  <a target="_blank" [routerLink]="['/produto/editar-produto', produtoId]" [style.color]="cor">
    {{nomeReduzido(nome, tamanhoNome)}}
  </a>
</span>
