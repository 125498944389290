import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Empresa } from 'src/app/shared/models/empresa';
import { TipoUsuarioEnum } from 'src/app/shared/models/enum/tipoUsuario.enum';
import { Usuario } from 'src/app/shared/models/usuario';
import { PermissoesService } from '../auth/permissoes.service';
import { LocalStorageObject } from '../services/localStorageObject.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {

  constructor(
    private readonly router: Router,
    private readonly permissoesService: PermissoesService,
    private readonly localStorageObject: LocalStorageObject
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean>|Promise<boolean>|boolean {
    const token = this.localStorageObject.token;

    if (!token) {
      return this.router.navigate(['/auth/login']);
    }

    return true;
  }

  canActivate(routeAc: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.localStorageObject.token;
    const moduloId = routeAc.data.moduloId;
    if (!token) {
      return this.router.navigate(['/auth/login']);
    }

    if (moduloId) {
      const podeExibir = this.permissoesService.podeExibirModulo(moduloId);
      if (!podeExibir) {
        return this.router.navigate(['']);
      }
    }

    return true;
  }

  redirecionarRotaRaizPermissao(usuario: Usuario, empresaSelecionada: Empresa): void {
    if (usuario.tipoUsuario !== TipoUsuarioEnum.ClienteResponsavel) {
      if (empresaSelecionada.tipo === 0) {
        const rotaAtual = this.router.url;
        if (rotaAtual === '/') {
          window.location.reload();
        } else {
          this.router.navigate(['']);
        }
      }
      if (empresaSelecionada.tipo === 1) {
        this.router.navigate(['financeiro/movimentacoes']);
      }
    } else {
      this.router.navigate(['cardapio-cliente']);
    }
  }


}
