<div class="row">
  <div class="col-md-12" [class.table-responsive]="isResponsive" [class.mb-3]="isResponsive" >
    <table class="table"
    [ngClass]="{'table-bordered': tabelaComBorda,
    'table-hover': tabelaHover,
    'table-sm': tabelaSm,
    'table-striped': tableStriped}">
      <ng-content></ng-content>
    </table>
  </div>
  <div class="col-md-2">
    <select class="custom-select" [(ngModel)]="itensPorPagina" (change)="alterarPagina($event)" [disabled]="!totalItems || totalItems < 10">
      <option [ngValue]="10">10</option>
      <option [ngValue]="25">25</option>
      <option [ngValue]="50">50</option>
      <option [ngValue]="100">100</option>
    </select>
  </div>
  <div class="col-md-10">
    <pagination class="float-right"
      [align]="true"
      [totalItems]="totalItems"
      previousText="&lsaquo;"
      [rotate]="centralizar"
      [maxSize]="tamanhoMaximo"
      nextText="&rsaquo;"
      firstText="&laquo;"
      lastText="&raquo;"
      [itemsPerPage]="itensPorPagina"
      (pageChanged)="alterarPagina($event)">
    </pagination>
  </div>
</div>
