import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import pageSettings from '../config/page-settings';
import { LocalStorageObject } from '../services/localStorageObject.service';
import { environment } from './../../../environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {

  @Input() pageSidebarTwo;
  @Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
  @Output() toggleMobileSidebar = new EventEmitter<boolean>();
  @Output() toggleMobileRightSidebar = new EventEmitter<boolean>();
  pageSettings = pageSettings;
  token: string;
  url: string;

  ambiente = environment.api;
  tipoAmbiente = environment.production;

  isExpanded = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public readonly localStorageObject: LocalStorageObject,
  ) {
    this.token = this.localStorageObject.token;

  }

  mobileSidebarToggle() {
    this.toggleMobileSidebar.emit(true);
  }
  mobileRightSidebarToggle() {
    this.toggleMobileRightSidebar.emit(true);
  }
  toggleSidebarRight() {
    this.toggleSidebarRightCollapsed.emit(true);
  }

  mobileTopMenuToggle() {
    this.pageSettings.pageMobileTopMenuToggled = !this.pageSettings.pageMobileTopMenuToggled;
  }

  mobileMegaMenuToggle() {
    this.pageSettings.pageMobileMegaMenuToggled = !this.pageSettings.pageMobileMegaMenuToggled;
  }

  ngOnDestroy() {
    this.pageSettings.pageMobileTopMenuToggled = false;
    this.pageSettings.pageMobileMegaMenuToggled = false;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  usuarioLogado(): boolean {
    this.token = this.localStorageObject.token;
    if (!this.token) {
      return false;
    }
    return true;
  }

  rotaAdmin() {
    if (this.activatedRoute.firstChild != null) {
      this.activatedRoute.firstChild.url.subscribe((urlPath) => {
        if (urlPath[0] != null) {
          this.url = urlPath[0].path;
        } else {
          return false;
        }
      });
    }

    if (this.url === 'admin') {
      return true;
    }
    return false;
  }

}
