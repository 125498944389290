import { EventEmitter } from '@angular/core';

export class ModalBloqueioPlanoService {

  notificador = new EventEmitter<any>();

  notificar(dados: any) {
    this.notificador.emit(dados);
  }

}
