import { DataPlanejamento } from './dataPlanejamento';
import { Planejamento } from 'src/app/shared/models/planejamento';

export class ClasseTipoPreparacaoPlanejamento {
  id: string;
  nome: string;
  dataCadastro: Date;
  dataAtualizacao: Date;
  empresaId: string;
  tipo: string;
  dataPlanejamento: DataPlanejamento[];
  planejamento: Planejamento[];
}
