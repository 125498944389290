import { SegmentoAtuacao } from './segmentoAtuacao';
import { UsuarioEmpresa } from './usuarioEmpresa';
import { ModulosImplementacao } from './modulosImplementacao';
import { Endereco } from './endereco';
import { PlanoEmpresa } from './planoEmpresa';
import { Impressao } from './impressao';

export class Empresa {
 id: string;
 nome: string;
 nomeFantasia: string;
 segmentoAtuacaoId: string;
 dataCadastro: Date;
 empresa: Empresa[];
 empresaPrincipalId: string;
 usuarioEmpresa: UsuarioEmpresa[];
 statusAcesso: boolean;
 cnpj: number;
 cpf: number;
 profissao: string;
 pagamentoAtual: boolean;
 implementacao: ModulosImplementacao[];
 endereco: Endereco;
 urlFoto: string;
 segmentoAtuacao: SegmentoAtuacao;
 permissaoPainelAdmin: string;
 ativo: boolean;
 planoEmpresaId: string;
 planoEmpresa: PlanoEmpresa;
 custoMedioAtivo: boolean;
 tipo: number;
 impressao: Impressao;
 impressaoId: string;
 ordemSaidaPorServico: boolean;
}
