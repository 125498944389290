import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CadastrarCliente } from 'src/app/shared/models/cadastrarCliente';
import { Usuario } from 'src/app/shared/models/usuario';
import { FiltroEmpresa } from 'src/app/shared/models/filtros/filtroEmpresa';
import { ListaPaginadaEmpresa } from 'src/app/shared/models/listas-paginadas/listaPaginadaEmpresa';
import { Empresa } from 'src/app/shared/models/empresa';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  obterListaPaginadaEmpresa(filtroEmpresa: FiltroEmpresa): Observable<ListaPaginadaEmpresa> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<ListaPaginadaEmpresa>(this.urlService + 'admin/obterListaPaginadaEmpresa', filtroEmpresa, super.ObterAuthHeaderJson());
  }

  obterListaPaginadaEmpresasPrincipais(filtroEmpresa: FiltroEmpresa): Observable<ListaPaginadaEmpresa> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<ListaPaginadaEmpresa>(this.urlService + 'admin/obterListaPaginadaEmpresasPrincipais', filtroEmpresa, super.ObterAuthHeaderJson());
  }

  obterEmpresaPorId(id: string): Observable<Empresa> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<Empresa>(this.urlService + 'admin/obterEmpresaPorId?id=' + id, super.ObterAuthHeaderJson());
  }

  cadastrarCliente(cadastrarCliente: CadastrarCliente): Observable<Usuario> {
    return this.http.post<Usuario>(this.urlService + 'admin/cadastrarCliente', cadastrarCliente, super.ObterAuthHeaderJson());
  }

  editarCliente(empresa: Empresa): Observable<Empresa> {
    return this.http.put<Empresa>(this.urlService + 'admin/editarCliente', empresa, super.ObterAuthHeaderJson());
  }

  editarClientePlanoBase(id: string): Observable<Empresa> {
    return this.http.put<Empresa>(this.urlService + 'admin/editarClientePlanoBase?id=' + id, super.ObterAuthHeaderJson());
  }

  obterFiliaisPorId(id: string): Observable<Empresa[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<Empresa[]>(this.urlService + 'admin/obterFiliaisPorId?id=' + id, super.ObterAuthHeaderJson());
  }
}

