export class FiltroPlanejamento {
  nome: string;
  dataInicio: Date;
  dataFim: Date;
  mapaProducaoId: string;
  listaMapaProducaoId: string[];
  mapaProducao: string[];
  tipoRefeicaoId: string[];
  empresaId: string;
  data: Date;
  dataDuplicar: Date[];
  dataRangerOrigem: Date[];
  dataInicioDuplicar: Date;
  quantidadeComensais: number;
}
