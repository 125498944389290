import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moduloPlano'
})
export class ModuloPlanoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let retorno = '';

    if (value === 'moduloAlimento') {
      retorno = 'Módulo Alimento';
    }
    if (value === 'moduloMaterialDiverso') {
      retorno = 'Módulo Material';
    }
    if (value === 'moduloProduto') {
      retorno = 'Módulo Produto';
    }
    if (value === 'moduloCardapio') {
      retorno = 'Módulo Cardápio';
    }
    if (value === 'moduloPedidos') {
      retorno = 'Módulo Pedidos';
    }
    if (value === 'moduloFornecedores') {
      retorno = 'Módulo Fornecedores';
    }
    if (value === 'moduloClientes') {
      retorno = 'Módulo Clientes';
    }
    if (value === 'submoduloFichaTecnicaBanco') {
      retorno = 'Submódulo Ficha Técnica Importações';
    }
    if (value === 'submoduloFichaTecnicaExcel') {
      retorno = 'Submódulo Ficha Técnica Excel';
    }
    if (value === 'submoduloFichaTecnicaImpressoes') {
      retorno = 'Submódulo Ficha Técnica Impressoes Personalizadas';
    }
    if (value === 'submoduloTiposClassesMotivo') {
      retorno = 'Submódulo Tipos e Classes ClassesMotivo';
    }
    if (value === 'submoduloTiposClassesGrupoClientes') {
      retorno = 'Submódulo Tipos e Classes Grupo Clientes';
    }
    if (value === 'submoduloTiposClassesCategoriaItemCardapio') {
      retorno = 'Submódulo Tipos e Classes CategoriaItemCardapio';
    }
    if (value === 'moduloMapaProducao') {
      retorno = 'Módulo Mapa de produção';
    }
    if (value === 'moduloReceitasCalculadas') {
      retorno = 'Módulo Receitas Calculadas';
    }
    if (value === 'moduloExecucao') {
      retorno = 'Módulo Execução';
    }
    if (value === 'moduloExpedicao') {
      retorno = 'Módulo Expedição';
    }
    if (value === 'moduloMovimentacao') {
      retorno = 'Módulo Movimentação';
    }
    if (value === 'moduloInventario') {
      retorno = 'Módulo Inventário';
    }
    if (value === 'impressaoLogo') {
      retorno = 'Impressão Logo';
    }
    if (value === 'quantidadeAcesso') {
      retorno = 'Quantidade Acessos';
    }
    if (value === 'quantidadeFichaTecnica') {
      retorno = 'Quantidade FTP';
    }
    if (value === 'quantidadeMapaProducao') {
      retorno = 'Quantidade Mapa de Produção';
    }
    if (value === 'moduloListaCompras') {
      retorno = 'Módulo Lista Compras';
    }
    if (value === 'moduloGerarCompras') {
      retorno = 'Módulo Gerar Compras';
    }
    if (value === 'moduloRelatorioCompras') {
      retorno = 'Módulo Relatorio Compras';
    }
    if (value === 'moduloFinanceiroMovimentacoes') {
      retorno = 'Módulo Financeiro Movimentações';
    }
    if (value === 'moduloFinanceiroCategorias') {
      retorno = 'Módulo Financeiro Categorias';
    }
    if (value === 'moduloFinanceiroCentroCusto') {
      retorno = 'Módulo Financeiro Centro de Custo';
    }
    if (value === 'moduloDashboard') {
      retorno = 'Módulo Dashboard';
    }
    if (value === 'moduloFichaTecnica') {
      retorno = 'Módulo Ficha Técnica';
    }
    if (value === 'moduloTiposClasses') {
      retorno = 'Módulo Tipos de Classes';
    }

    //Grupos de menus
    if (value === 'moduloEstoque') {
      retorno = 'Módulo Estoque';
    }
    if (value === 'moduloCompras') {
      retorno = 'Módulo Compras';
    }
    if (value === 'moduloRelatórios') {
      retorno = 'Módulo Relatórios';
    }
    if (value === 'moduloProdução') {
      retorno = 'Módulo Produção';
    }

    
    return retorno;
  }

}