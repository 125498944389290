<div class="card-columns">


  <!-- Ficha Tecnica -->
  <!-- <div class="col-3 grid-margin" id="">
    <div class="card bg-gradient-success card-img-holder text-white">
      <div class="card-body">
        <img src="/assets/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3 text-white">Fichas técnicas<em
            class="mdi mdi-diamond mdi-24px float-right"></em>
        </h4>
        <h2 class="mb-3 text-white" *ngIf="qtdFichaTecnica > 0 ">{{ qtdFichaTecnica }}</h2>
        <button class="btn btn-outline-light text-green">
          <h6 class="card-text text-white text-green" [routerLink]="['/fichas-tecnicas']">Visualizar <em
              class="fas fa-eye"></em></h6>
        </button>
      </div>
    </div>
  </div> -->

  <!-- Mapa de Producao -->
  <div class="card" *ngIf="podeExibirModuloPlano(['moduloMapaProducao']); else mapaProducaoBlocked" id="stepDashboard1">
    <div class="card-header inline bg-gradient-success d-flex justify-content-between">
      <h2 class="card-title mt-3 text-white">Mapa de produção <em class="fas fa-info-circle" placement="right"
        [popover]="'Planejamentos do mapa de produção selecionado.'" triggers="mouseenter:mouseleave"></em></h2>
      <button class="btn btn-outline-light text-green" (click)='redirecionarMapaProducao()'>
        <a class="card-text text-white text-green">Visualizar <em class="fas fa-eye"></em></a>
      </button>
    </div>
    <div class="p-2 bg-gradient-success">
      <h7 class="text-white">Selecione um mapa de produção</h7>
      <ng-select class="bg-gradient-success" [items]="itensMapaDeProducao" [searchable]="true" [clearable]="false"
        style="flex: 1 1 auto;" notFoundText="Nenhum item encontrado" bindLabel="nome" bindValue="id" [multiple]="false"
        [(ngModel)]="mapaProducaoSelecionadoId" (change)="selecionarMapaProducao()">
        <ng-template ng-option-tmp let-item="item">
          <div><span>{{item?.nome}}</span></div>
        </ng-template>
      </ng-select>
    </div>

    <div class=" p-2 bg-gradient-success">

      <div class="table-responsive tableFixHead card">
        <table class="table table-condensed table-sm table-striped table-bordered" aria-describedby="">
          <thead>
            <tr>
              <th scope="col">Serviço</th>
              <th scope="col">Tipo</th>
              <th scope="col">Nome</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let tipoRefeicao of listaTipoRefeicao; index as iRefeicao">
              <ng-container
                *ngFor="let classeTipoPreparacaoPlanejamento of tipoRefeicao.classeTipoPreparacaoPlanejamento; index as i">
                <tr [style.border-top]="i === 0 && iRefeicao !== 0 ? '10px solid #02a96b' : ''">
                  <td class="align-middle text-center bg-white font-weight-bolder font-tamanho-10" *ngIf="i === 0"
                    [attr.rowspan]="tipoRefeicao.classeTipoPreparacaoPlanejamento.length">
                    {{nomeReduzido(tipoRefeicao?.nome)}}
                  </td>
                  <td class="align-middle text-center">
                    <div class="font-weight-bolder font-tamanho-9">
                      <div>{{nomeReduzido(classeTipoPreparacaoPlanejamento?.nome)}}</div>
                    </div>
                  </td>
                  <td>
                    <ul class="list-group">
                      <li class="list-group-item list-group-item-action"
                        *ngFor="let planejamento of classeTipoPreparacaoPlanejamento.planejamento"
                        [style.border]="'2px solid ' + planejamento?.fichaTecnica?.cor" triggers="mouseenter:mouseleave"
                        [adaptivePosition]="false" containerClass="customPopover" placement="left">
                        <span class="text-nowrap">
                          <app-nome-reduzido [nome]="mPHelperService.nomeItemPlanejamento(planejamento)"
                            [fichaTecnicaId]="planejamento?.fichaTecnicaId"
                            [materialDiversoId]="planejamento?.materialDiversoId"
                            [alimentoId]="planejamento?.alimentoId" [produtoId]="planejamento?.produtoId"
                            [tamanhoNome]="16">
                          </app-nome-reduzido>
                        </span>
                      </li>
                    </ul>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
          <tbody *ngIf="itensMapaDeProducao?.length === 0">
            <tr>
              <td colspan="3" class="text-center">
                Nenhum item na lista
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-template #mapaProducaoBlocked>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title mt-3 bg-secundary">
          <i class="fa fa-lock"></i>
          Mapa de Produção
          <em class="fas fa-info-circle" placement="right" [popover]="'Por favor entre em contato para ter mais informações de como obter acesso.'"
            triggers="mouseenter:mouseleave">
          </em>
        </h3>
      </div>
    </div>
  </ng-template>





  <!-- Proximas validades -->
  <div class="card" *ngIf="podeExibirModuloPlano(['moduloMovimentacao']); else proximasValidadesBlocked" id="stepDashboard2">
    <div class="card-header">
      <h2 class="card-title mt-3">Próximas validades <em class="fas fa-info-circle" placement="right"
        [popover]="'Alimentos com o prazo de até 7 dias para vencimento.'" triggers="mouseenter:mouseleave"></em></h2>
    </div>
    <div class="card-body">

      <div class="table-responsive">
        <table class="table table-condensed table-sm table-striped table-bordered" aria-describedby="">
          <thead>
            <tr>
              <th scope="col"> Nome do alimento </th>
              <th scope="col"> Marca </th>
              <th scope="col" class="text-right"> Data de validade </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of itensEstoqueProximoVal">
              <td class="align-middle">
                <app-nome-reduzido [nome]="item.nome" [fichaTecnicaId]="item.fichaTecnicaId"
                  [alimentoId]="item.alimentoId" [materialDiversoId]="item.materialDiversoId">
                </app-nome-reduzido>
              </td>
              <td class="align-middle">
                {{item.alimento?.marca}}
              </td>
              <td class="text-right"> {{item?.validade | date: 'dd/MM/yyyy - HH:mm:ss'}} </td>
            </tr>
          </tbody>
          <tbody *ngIf="itensEstoqueProximoVal?.length === 0">
            <tr>
              <td colspan="3" class="text-center">
                Nenhum item na lista
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-template #proximasValidadesBlocked>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title mt-3 bg-secundary">
          <i class="fa fa-lock"></i>
          Próximas validades
          <em class="fas fa-info-circle" placement="right" [popover]="'Por favor entre em contato para ter mais informações de como obter acesso.'"
            triggers="mouseenter:mouseleave">
          </em>
        </h3>
      </div>
    </div>
  </ng-template>


  <!-- Lista de Compras -->
  <div class="card" *ngIf="podeExibirModuloPlano(['moduloListaCompras']) ; else listaComprasBlocked" id="stepDashboard4">
    <div class="card-header">
      <h2 class="card-title mt-3">Lista de compras <em class="fas fa-info-circle" placement="right"
        [popover]="'Compras que estão com status Pendente.'" triggers="mouseenter:mouseleave"></em></h2>
    </div>
    <div class="card-body">

      <div class="table-responsive tableFixHead">
        <table class="table table-condensed table-sm table-striped table-bordered" aria-describedby="">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Data Planejamento</th>
              <th class="text-right" scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of itensListaCompra">
              <td class="align-middle"> {{item?.nome}}</td>
              <td><span class="text-success font-weight-bolder">{{item?.dataInicio | date: 'dd/MM/yyyy - HH:mm:ss'}}</span> a
                <span class="text-success font-weight-bolder">{{item?.dataFim | date: 'dd/MM/yyyy - HH:mm:ss'}}</span>
              </td>
              <td class="text-right align-middle">
                <label class="badge badge-danger">PENDENTE</label>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="itensListaCompra?.length === 0">
            <tr>
              <td colspan="3" class="text-center">
                Nenhum item na lista
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-template #listaComprasBlocked>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title mt-3 bg-secundary">
          <i class="fa fa-lock"></i>
          Lista de compras
          <em class="fas fa-info-circle" placement="right" [popover]="'Por favor entre em contato para ter mais informações de como obter acesso.'"
            triggers="mouseenter:mouseleave">
          </em>
        </h3>
      </div>
    </div>
  </ng-template>


  <!-- Saidas Planejadas -->
  <div class="card" *ngIf="podeExibirModuloPlano(['moduloMovimentacao']); else saidasPlanejadasBlocked" id="stepDashboard5">
    <div class="card-header">
      <h2 class="card-title mt-3">Saídas planejadas <em class="fas fa-info-circle" placement="right"
          [popover]="'Saidas que estão planejadas para a data de hoje.'" triggers="mouseenter:mouseleave"></em></h2>
    </div>
    <div class="card-body">

      <div class="table-responsive tableFixHead">
        <table class="table table-condensed table-sm table-striped table-bordered" aria-describedby="">
          <thead>
            <tr>
              <th scope="col">Nome do item</th>
              <th scope="col">Quantidade</th>
              <th scope="col">Unidade de compra</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of ordemSaida.itemOrdemSaida">
              <td class="align-middle">
                <app-nome-reduzido *ngIf="item.materialDiverso" [nome]="item.materialDiverso.nome"
                  [materialDiversoId]="item.materialDiversoId"></app-nome-reduzido>
                <app-nome-reduzido *ngIf="item.alimento" [nome]="item.alimento.nome" [alimentoId]="item.alimentoId">
                </app-nome-reduzido>
              </td>
              <td class="text-right align-middle">
                {{item?.quantidadePlanejadaTemp | decimal}}
                {{estoqueHelperService.nomeUnidadeCompraSelecionada(item)}}
              </td>
              <td class="text-right align-middle">
                {{item?.unidadeCompra?.nome}}
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="ordemSaida?.itemOrdemSaida?.length === 0">
            <tr>
              <td colspan="3" class="text-center">
                Nenhum item na lista
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-template #saidasPlanejadasBlocked>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title mt-3 bg-secundary">
          <i class="fa fa-lock"></i>
          Saídas planejadas
          <em class="fas fa-info-circle" placement="right" [popover]="'Por favor entre em contato para ter mais informações de como obter acesso.'"
            triggers="mouseenter:mouseleave">
          </em>
        </h3>
      </div>
    </div>
  </ng-template>

  <!-- Estoque Minimo -->
  <div class="card" *ngIf="podeExibirModuloPlano(['moduloMovimentacao']);else estoqueMinimoBlocked" id="stepDashboard3">
    <div class="card-header">
      <h2 class="card-title mt-3">Estoque mínimo <em class="fas fa-info-circle" placement="right"
        [popover]="'Alimentos que estão abaixo do estoque mínimo.'" triggers="mouseenter:mouseleave"></em></h2>
    </div>
    <div class="card-body">

      <div class="table-responsive tableFixHead">
        <table class="table table-condensed table-sm table-striped table-bordered" aria-describedby="">
          <thead>
            <tr>
              <th scope="col"> Nome do item </th>
              <th scope="col"> Marca </th>
              <th scope="col" class="text-right"> Mínimo em estoque </th>
              <th scope="col" class="text-right"> Quantidade </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of itensEstoqueMinimo">
              <td class="align-middle">
                <app-nome-reduzido [nome]="item.nome" [fichaTecnicaId]="item.fichaTecnicaId"
                  [alimentoId]="item.alimentoId" [materialDiversoId]="item.materialDiversoId">
                </app-nome-reduzido>
              </td>
              <td class="align-middle">
                <span *ngIf="item.alimento">
                  {{item.alimento?.marca}}
                </span>
                <span *ngIf="item.materialDiverso">
                  {{item.materialDiverso?.marca}}
                </span>
              </td>
              <td class="text-right align-middle"> {{item?.quantidadeMinimaEstoque}}
                {{item.unidadeQuantidadeMinimaEstoque}} </td>
              <td class="text-right align-middle">
                <span *ngIf="item.alimento">
                  {{item?.quantidadeEstoque | unidadeMedida : '' : item.alimento.liquido}}
                </span>
                <span *ngIf="item.materialDiverso">
                  {{item?.quantidadeEstoque}} unidade<span *ngIf="item.quantidadeEstoque > 1">s</span>
                </span>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="itensEstoqueMinimo?.length === 0">
            <tr>
              <td colspan="4" class="text-center">
                Nenhum item na lista
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-template #estoqueMinimoBlocked>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title mt-3 bg-secundary">
          <i class="fa fa-lock"></i>
          Estoque mínimo
          <em class="fas fa-info-circle" placement="right" [popover]="'Por favor entre em contato para ter mais informações de como obter acesso.'"
            triggers="mouseenter:mouseleave">
          </em>
        </h3>
      </div>
    </div>
  </ng-template>

</div>
