import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Empresa } from '../models/empresa';
import { Plano } from '../models/plano';
import { ModalBloqueioPlanoService } from './modal-bloqueio-plano.service';
import { tap, switchMap } from 'rxjs/operators';
import { isString } from 'util';
import { LocalStorageObject } from 'src/app/core/services/localStorageObject.service';

@Component({
  selector: 'app-modal-bloqueio-plano',
  templateUrl: './modal-bloqueio-plano.component.html',
  styleUrls: ['./modal-bloqueio-plano.component.scss']
})
export class ModalBloqueioPlanoComponent implements OnInit {

  // @ViewChild(TemplateRef, { static: true }) modalMensagemPlano: TemplateRef;
  @ViewChild(TemplateRef, { static: false }) modalMensagemPlano: TemplateRef<any>;

  bsModalRef: BsModalRef;

  config = {
    backdrop: false,
    ignoreBackdropClick: true,
    class: 'modal-md'
  };

  empresaSelecionada: Empresa;
  plano?: Plano;
  nomeModuloPlano?: string;
  quantidadeAcesso?: number;
  quantidadeFichaTecnica?: number;
  quantidadeMapaProducao?: number;
  texto?: number;

  constructor(
    private readonly modalBloqueioPlanoService: ModalBloqueioPlanoService,
    private readonly modalService: BsModalService,
    private readonly localStorageObject: LocalStorageObject
  ) {
    this.nomeModuloPlano = '';
  }

  ngOnInit() {
    this.obterPlanoEmpresaSelecionada();

    this.modalBloqueioPlanoService.notificador.subscribe((dados: any) => this.abrirModal(this.modalMensagemPlano, dados));
  }

  abrirModal(modalMapaProducao: TemplateRef<any>, dados: any) {
    this.nomeModuloPlano = null;
    this.quantidadeAcesso = null;
    this.quantidadeFichaTecnica = null;
    this.quantidadeMapaProducao = null;
    this.texto = null;
    if (dados.quantidadeAcesso) {
      this.quantidadeAcesso = dados.quantidadeAcesso;
    }
    if (dados.quantidadeFichaTecnica) {
      this.quantidadeFichaTecnica = dados.quantidadeFichaTecnica;
    }
    if (dados.quantidadeMapaProducao) {
      this.quantidadeMapaProducao = dados.quantidadeMapaProducao;
    }
    if (dados.modulo) {
      this.nomeModuloPlano = dados.modulo;
    }
    if (dados.mensagemCadastroConta) {
      this.texto = dados.mensagemCadastroConta;
    }
    this.obterPlanoEmpresaSelecionada();
    this.bsModalRef = this.modalService.show(modalMapaProducao, this.config);
  }

  obterPlanoEmpresaSelecionada() {
    this.empresaSelecionada = this.localStorageObject.empresaSelecionada;
    if (this.empresaSelecionada && this.empresaSelecionada.planoEmpresa && this.empresaSelecionada.planoEmpresa.plano) {
      this.plano = this.empresaSelecionada.planoEmpresa.plano;
    }
  }

  fecharModal() {
    this.bsModalRef.hide();
  }

}
