import { HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Empresa } from 'src/app/shared/models/empresa';
import { PlanoEmpresa } from 'src/app/shared/models/planoEmpresa';
import { Usuario } from 'src/app/shared/models/usuario';

export abstract class BaseService {
  public urlService = environment.api;

  protected ObterHeaderJson() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }

  protected ObterAuthHeaderJson() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.obterTokenUsuario()}`,
      }),
    };
  }

  protected ObterAuthHeaderString() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        responseType: 'text',
        Authorization: `Bearer ${this.obterTokenUsuario()}`,
      }),
    };
  }

  public obterEmpresaSelecionada(): Empresa {
    let empresaSelecionada = new Empresa();
    empresaSelecionada = JSON.parse(localStorage.getItem('rotulagem.empresaSelecionada'));
    return empresaSelecionada;
  }

  public obterUsuario(): Usuario {
    let usuario = new Usuario();
    usuario = JSON.parse(localStorage.getItem('rotulagem.usuario'));
    return usuario;
  }

  public obterTokenUsuario(): string {
    return localStorage.getItem('rotulagem.token');
  }

  protected extractData(response: any) {
    return response.data || {};
  }

  protected serviceError(error: Response | any) {
    let errMsg: string;

    if (error instanceof Response) {
      errMsg = `${error.status} - ${error.statusText || ''}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(error);
    throw new Error(error);
  }

  protected obterPlanoEmpresa(): PlanoEmpresa {
    let planoEmpresa = new PlanoEmpresa();
    const empresaSelecionada = JSON.parse(localStorage.getItem('rotulagem.empresaSelecionada'));
    planoEmpresa = empresaSelecionada.planoEmpresa as PlanoEmpresa;
    return planoEmpresa;
  }
}
