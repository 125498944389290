import { NgModule } from '@angular/core';
import { FilterValueBooleanPipe } from './filterValueBoolean.pipe';
import { UnidadeMedidaPipe } from './unidadeMedida.pipe';
import { OrganizarListaPipe } from './organizarLista.pipe';
import { DecimalPipe } from './decimal.pipe';
import { CNPJPipe } from './cnpj.pipe';
import { PermissaoPipe } from './permissao.pipe';
import { DescricaoModuloPipe } from './descricaoModulo.pipe';
import { TipoPlanoPipe } from './tipo-plano.pipe';
import { ModuloPlanoPipe } from './moduloPlano.pipe';
import { TipoEmpresaPipe } from './tipoEmpresa.pipe';
import { MovimentacaoTransferenciaStatusPipe } from './movimentacaoTransferenciaStatus.pipe';
import { StatusPedidoClienteResponsavelPipe } from './statusPedidoClienteResponsavel.pipe';

@NgModule({
  declarations: [
    FilterValueBooleanPipe,
    UnidadeMedidaPipe,
    OrganizarListaPipe,
    DecimalPipe,
    CNPJPipe,
    PermissaoPipe,
    DescricaoModuloPipe,
    TipoPlanoPipe,
    ModuloPlanoPipe,
    TipoEmpresaPipe,
    StatusPedidoClienteResponsavelPipe,
    MovimentacaoTransferenciaStatusPipe,
  ],
  imports: [],
  exports: [
    FilterValueBooleanPipe,
    UnidadeMedidaPipe,
    OrganizarListaPipe,
    DecimalPipe,
    CNPJPipe,
    PermissaoPipe,
    DescricaoModuloPipe,
    TipoPlanoPipe,
    ModuloPlanoPipe,
    TipoEmpresaPipe,
    StatusPedidoClienteResponsavelPipe,
    MovimentacaoTransferenciaStatusPipe,
  ],
})
export class PipesModule {}
