
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { AuthGuard } from './core/authentication/auth.guard';

const routes: Routes = [
  { path: '', canActivate: [AuthGuard], component: HomeComponent },
  { path: 'dashboard', canActivate: [AuthGuard], component: HomeComponent },
  { path: 'cadastros', loadChildren: () => import('./modules/configuracao/configuracao.module').then(m => m.ConfiguracaoModule) },
  { path: 'auth', loadChildren: () => import('./core/auth/auth.module').then(m => m.AuthModule) },
  { path: 'alimentos', loadChildren: () => import('./modules/alimentos/alimentos.module').then(m => m.AlimentosModule) },
  { path: 'materiais-diversos', loadChildren: () => import('./modules/materiais-diversos/materiais-diversos.module').then(m => m.MateriaisDiversosModule) },
  { path: 'fichas-tecnicas', loadChildren: () => import('./modules/fichas-tecnicas/fichas-tecnicas.module').then(m => m.FichasTecnicasModule) },
  { path: 'produto', loadChildren: () => import('./modules/produto/produto.module').then(m => m.ProdutoModule) },
  { path: 'cardapio', loadChildren: () => import('./modules/cardapio/cardapio.module').then(m => m.CardapioModule) },
  { path: 'mapa-producao', loadChildren: () => import('./modules/mapa-producao/mapa-producao.module').then(m => m.MapaProducaoModule) },
  { path: 'compras', loadChildren: () => import('./modules/compras/compras.module').then(m => m.ComprasModule) },
  { path: 'estoque', loadChildren: () => import('./modules/estoque/estoque.module').then(m => m.EstoqueModule) },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
  { path: 'painel-admin', loadChildren: () => import('./modules/painel-admin/painel-admin.module').then(m => m.PainelAdminModule) },
  { path: 'usuario', loadChildren: () => import('./modules/usuario/usuario.module').then(m => m.UsuarioModule) },
  { path: 'execucao', loadChildren: () => import('./modules/execucao/execucao.module').then(m => m.ExecucaoModule) },
  { path: 'relatorios', loadChildren: () => import('./modules/relatorios/relatorios.module').then(m => m.RelatoriosModule) },
  { path: 'expedicao', loadChildren: () => import('./modules/expedicao/expedicao.module').then(m => m.ExpedicaoModule) },
  { path: 'pedido', loadChildren: () => import('./modules/pedido/pedido.module').then(m => m.PedidoModule) },
  { path: 'financeiro', loadChildren: () => import('./modules/financeiro/financeiro.module').then(m => m.FinanceiroModule) },
  { path: 'cardapio-cliente', loadChildren: () => import('./modules/cliente-responsavel/cliente-responsavel.module').then(m => m.ClienteResponsavelModule) },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
