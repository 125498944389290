import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() totalItems: number;

  @Input() centralizar = true;
  @Input() tamanhoMaximo = 5;
  @Input() isResponsive = false;
  @Input() tabelaComBorda = false;
  @Input() tabelaHover = false;
  @Input() tabelaSm = true;
  @Input() tableStriped = true;
  @Input() itensPorPagina = 10;

  @Output() alterouPagina = new EventEmitter();

  alterouQuantidadePorPagina = new EventEmitter<PageChangedEvent>();


  constructor() { }

  ngOnInit() { }

  alterarPagina(event: PageChangedEvent): void {
    event.itemsPerPage = this.itensPorPagina;
    this.alterouPagina.emit(event);
  }

}
