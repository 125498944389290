import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nome-reduzido',
  templateUrl: './nome-reduzido.component.html',
  styleUrls: ['./nome-reduzido.component.scss']
})
export class NomeReduzidoComponent implements OnInit {

  @Input() nome = '';
  @Input() alimentoId: string;
  @Input() fichaTecnicaId: string;
  @Input() materialDiversoId: string;
  @Input() produtoId: string;
  @Input() tamanhoNome = 16;
  @Input() popoverPlacement = 'top';
  @Input() popoverDisabled = false;
  @Input() cor: string;

  constructor() { }

  ngOnInit() {
  }

  nomeReduzido(nome: string, tamanhoNome): string {
    let retorno = '';

    if (nome.length > tamanhoNome) {
      retorno = nome.substring(0, tamanhoNome) + '...';
    } else {
      retorno = nome;
    }
    return retorno;
  }

}
