import { DashboardService } from './../../core/services/dashboard.service';
import { FiltroDashboard } from './../../shared/models/filtroDashboard';
import { Component, OnInit } from '@angular/core';
import { Planejamento } from 'src/app/shared/models/planejamento';
import { MapaProducao } from 'src/app/shared/models/mapaProducao';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker/models';
import { Data, Router } from '@angular/router';
import { ItemInventario } from 'src/app/shared/models/itemInventario';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { LocalStorageObject } from 'src/app/core/services/localStorageObject.service';
import { Empresa } from 'src/app/shared/models/empresa';
import { PermissoesService } from 'src/app/core/auth/permissoes.service';
import { PlanejamentoService } from 'src/app/core/services/planejamento.service';
import { FiltroPlanejamento } from 'src/app/shared/models/filtros/filtroPlanejamento';
import { TipoRefeicaoPlanejamento } from '../mapa-producao/models/tipoRefeicaoPlanejamento';
import { ClasseTipoPreparacaoPlanejamento } from '../mapa-producao/models/classeTipoPreparacaoPlanejamento';
import { DataPlanejamento } from '../mapa-producao/models/dataPlanejamento';
import { MapaProducaoHelperService } from '../mapa-producao/mapa-producao-helper.service';
import { FiltroOrdemSaida } from 'src/app/shared/models/filtros/filtroOrdemSaida';
import { OrdemSaida } from '../estoque/ordem-saida/models/ordemSaida';
import { EstoqueHelperService } from '../estoque/estoque-helper.service';
import { Observable, forkJoin , of, EMPTY} from 'rxjs';
import { FiltroMapaProducao } from 'src/app/shared/models/filtros/filtroMapaProducao';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  date = new Date();
  listaPlanejamentoNutrientes: Planejamento[];
  ultimoDia = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  datasDesabilitadas: Data[];
  isDisabled = true;
  dateCustomClasses: DatepickerDateCustomClasses[];
  qtdFichaTecnica: any;
  itensEstoqueMinimo: ItemInventario[];
  itensEstoqueProximoVal: any;
  itensListaCompra: any;
  itensMapaDeProducao: MapaProducao[];
  itensPlanejamentos: Planejamento[];
  ordemSaida: OrdemSaida;
  listaTipoRefeicao: TipoRefeicaoPlanejamento[];
  mapaProducaoSelecionado: MapaProducao;
  mapaProducaoSelecionadoId: string;

  constructor(
    private readonly localeService: BsLocaleService,
    private readonly dashboardService: DashboardService,
    private readonly localStorageObject: LocalStorageObject,
    private readonly permissoesService: PermissoesService,
    private readonly planejamentoService: PlanejamentoService,
    public readonly mPHelperService: MapaProducaoHelperService,
    private readonly router: Router,
    public  readonly estoqueHelperService: EstoqueHelperService,
  ) {

    this.datasDesabilitadas = new Array<Date>();
    this.localeService.use('pt-br');
    this.date.setDate(1);
    this.date.toLocaleDateString('pt-BR');
    this.itensEstoqueMinimo = new Array<ItemInventario>();
    this.itensEstoqueProximoVal = new Array<any>();
    this.itensListaCompra = new Array<any>();
    this.itensMapaDeProducao = new Array<MapaProducao>();
    this.ordemSaida = new OrdemSaida();
    this.qtdFichaTecnica = 0;
    this.listaTipoRefeicao = new Array<TipoRefeicaoPlanejamento>();
  }

  ngOnInit() {
    if (!this.localStorageObject?.empresaSelecionada?.id) { return; }

    this.blockUI.start();

    const filtroDashboard = new FiltroDashboard();
    filtroDashboard.empresaId = this.localStorageObject?.empresaSelecionada?.id;

    const observables: Observable<any>[] = [];

    // Estoque Minimo
    if (this.podeExibirModuloPlano(['moduloMovimentacao'])) {
      observables.push(this.dashboardService.obterItensEstoqueMinimo(filtroDashboard));
    } else {
      observables.push(of({}));
    }

    // Próximas Validades
    if (this.podeExibirModuloPlano(['moduloMovimentacao'])) {
      observables.push(this.dashboardService.obterItensEstoqueProximoValidade(filtroDashboard));
    }else {
      observables.push(of({}));
    }

    // Lista de compras
    if (this.podeExibirModuloPlano(['moduloListaCompras'])) {
      observables.push(this.dashboardService.obterListasDeComprasPendentes(filtroDashboard));
    }else {
      observables.push(of({}));
    }

    // Saídas Planejadas
    if (this.podeExibirModuloPlano(['moduloMovimentacao'])) {
      const filtroOrdemSaida = new FiltroOrdemSaida();
      filtroOrdemSaida.empresaId = this.localStorageObject?.empresaSelecionada?.id;
      filtroOrdemSaida.dataPlanejamento = new Date();
      filtroOrdemSaida.dataPlanejamento.setHours(0, 0, 0);
      observables.push(this.dashboardService.obterItensOrdemSaida(filtroOrdemSaida));
    }else {
      observables.push(of({}));
    }

    // Mapas de produção.
    if (this.podeExibirModuloPlano(['moduloMapaProducao'])) {
      const filtroMapaProducao = new FiltroMapaProducao();
      filtroMapaProducao.empresaId = this.localStorageObject?.empresaSelecionada?.id;
      observables.push(this.dashboardService.obterListaMapaProducao(filtroMapaProducao));
    }else {
      observables.push(of({}));
    }

    forkJoin(observables).subscribe(([itensEstoqueMinimo, itensEstoqueProximoValidade, listasDeCompras, listaOrdemSaida, listaMapaProducao]) => {
      this.itensEstoqueMinimo = itensEstoqueMinimo;
      this.itensEstoqueProximoVal = itensEstoqueProximoValidade;
      this.itensListaCompra = listasDeCompras;

      if (listaOrdemSaida.itemOrdemSaida) {
        this.listaCarregadaSucessoOrdemSaida(listaOrdemSaida);
      }

      this.itensMapaDeProducao = listaMapaProducao;

      if (this.itensMapaDeProducao.length > 0) {
        this.mapaProducaoSelecionadoId = this.itensMapaDeProducao[0].id;
        this.mapaProducaoSelecionado = this.itensMapaDeProducao[0];
        this.obterPlanejamento();
      }

      this.blockUI.reset();

    });
  }

  listaCarregadaSucessoOrdemSaida(resultado: OrdemSaida): void {
    this.ordemSaida = resultado;

    this.ordemSaida.itemOrdemSaida.forEach(itemOrdemSaida => {

      itemOrdemSaida.quantidadePlanejada = itemOrdemSaida.quantidadeTotal;
      itemOrdemSaida.quantidadeSaida = itemOrdemSaida.quantidadeTotal;
      itemOrdemSaida.unidadeQuantidadeSaida = itemOrdemSaida.unidadeQuantidadeTotal;
      itemOrdemSaida.quantidadeCozinha = 0;


      if (itemOrdemSaida.alimento) {

        const unidadeCompraPrincipal = itemOrdemSaida.alimento.unidadeCompra.find(x => x.principal === true);
        itemOrdemSaida.unidadeCompraId = unidadeCompraPrincipal ? unidadeCompraPrincipal.id : null;
        itemOrdemSaida.unidadeCompra = unidadeCompraPrincipal ? unidadeCompraPrincipal : null;

        if (itemOrdemSaida.alimento.liquido) {
          itemOrdemSaida.unidadeQuantidadeCozinha = 'L';
        } else {
          itemOrdemSaida.unidadeQuantidadeCozinha = 'kg';
        }

        if (itemOrdemSaida.alimento.quantidadeEstoque < itemOrdemSaida.quantidadeTotal) {
          itemOrdemSaida.selecionado = false;
          itemOrdemSaida.bloqueado = true;

          if (itemOrdemSaida.quantidadeTotal === 0) {
            itemOrdemSaida.totalRetirado = true;
            itemOrdemSaida.bloqueado = true;
            itemOrdemSaida.selecionado = false;
          }
          itemOrdemSaida.quantidadeTotal = 0;
          if (itemOrdemSaida.alimento.quantidadeEstoque > 0) {
            itemOrdemSaida.TotalEstoqueMenorQuantidadeTotal = true;
            itemOrdemSaida.bloqueado = false;
          }
        } else {
          itemOrdemSaida.selecionado = true;
          itemOrdemSaida.bloqueado = false;

          if (itemOrdemSaida.quantidadeTotal === 0) {
            itemOrdemSaida.totalRetirado = true;
            itemOrdemSaida.bloqueado = true;
            itemOrdemSaida.selecionado = false;
          }
        }

        if (itemOrdemSaida.alimento.quantidadeEstoque === 0) {
          itemOrdemSaida.totalEstoqueZero = true;
        }

      } else {
        const unidadeCompraPrincipal = itemOrdemSaida.materialDiverso.unidadeCompra.find(x => x.principal === true);
        itemOrdemSaida.unidadeCompraId = unidadeCompraPrincipal ? unidadeCompraPrincipal.id : null;
        itemOrdemSaida.unidadeCompra = unidadeCompraPrincipal ? unidadeCompraPrincipal : null;

        if (itemOrdemSaida.materialDiverso.quantidadeEstoque < itemOrdemSaida.quantidadeTotal) {
          itemOrdemSaida.selecionado = false;
          itemOrdemSaida.bloqueado = true;

          if (itemOrdemSaida.quantidadeTotal === 0) {
            itemOrdemSaida.totalRetirado = true;
            itemOrdemSaida.bloqueado = true;
            itemOrdemSaida.selecionado = false;
          }
          itemOrdemSaida.quantidadeTotal = 0;
          if (itemOrdemSaida.materialDiverso.quantidadeEstoque > 0) {
            itemOrdemSaida.TotalEstoqueMenorQuantidadeTotal = true;
            itemOrdemSaida.bloqueado = false;
          }
        } else {
          itemOrdemSaida.selecionado = true;
          itemOrdemSaida.bloqueado = false;


          if (itemOrdemSaida.quantidadeTotal === 0) {
            itemOrdemSaida.totalRetirado = true;
            itemOrdemSaida.bloqueado = true;
            itemOrdemSaida.selecionado = false;
          }
        }

        if (itemOrdemSaida.materialDiverso.quantidadeEstoque === 0) {
          itemOrdemSaida.totalEstoqueZero = true;
        }

      }
      this.estoqueHelperService.calcularQuantidades(itemOrdemSaida);
      itemOrdemSaida.quantidadeTotal = 0;
    });
  }

  selecionarMapaProducao() {
    this.mapaProducaoSelecionado = this.itensMapaDeProducao.find(x => x.id === this.mapaProducaoSelecionadoId);
    this.obterPlanejamento();
  }

  obterPlanejamento() {

    const filtroPlanejamento = new FiltroPlanejamento();
    filtroPlanejamento.listaMapaProducaoId = new Array<string>();
    filtroPlanejamento.listaMapaProducaoId.push(this.mapaProducaoSelecionadoId);
    filtroPlanejamento.empresaId = this.localStorageObject?.empresaSelecionada?.id;
    filtroPlanejamento.dataInicio = new Date();
    filtroPlanejamento.dataFim = new Date();

    this.planejamentoService.obterPlanejamento(filtroPlanejamento)
      .subscribe(resultadoitensPlanejamentos => {
        this.itensPlanejamentos = resultadoitensPlanejamentos;

        this.listaTipoRefeicao = new Array<TipoRefeicaoPlanejamento>();

        this.itensPlanejamentos.forEach(planejamentoTemp => {

          const tipoRefeicaoNaLista = this.listaTipoRefeicao.find(x => x.id === planejamentoTemp.tipoRefeicaoId);

          if (!tipoRefeicaoNaLista) {
            const tipoRefeicaoPlanjamento = new TipoRefeicaoPlanejamento();
            tipoRefeicaoPlanjamento.id = planejamentoTemp.tipoRefeicao.id;
            tipoRefeicaoPlanjamento.nome = planejamentoTemp.tipoRefeicao.nome;

            if (planejamentoTemp?.alimento) {
              if (!tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento) {
                tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento = new Array<ClasseTipoPreparacaoPlanejamento>();
              }
              // tslint:disable-next-line: max-line-length
              const classeNaLista = tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento.find(x => x.id === (planejamentoTemp.alimento && planejamentoTemp.alimento.classe.id));
              if (!classeNaLista && planejamentoTemp.alimento) {
                const classeTipoPreparacaoPlanejamentoTemp = new ClasseTipoPreparacaoPlanejamento();
                classeTipoPreparacaoPlanejamentoTemp.id = planejamentoTemp.alimento.classe.id;
                classeTipoPreparacaoPlanejamentoTemp.nome = planejamentoTemp.alimento.classe.nome;
                classeTipoPreparacaoPlanejamentoTemp.tipo = 'Classe Alimento';
                classeTipoPreparacaoPlanejamentoTemp.dataPlanejamento = new Array<DataPlanejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento = new Array<Planejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento.push(planejamentoTemp);
                tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento.push(classeTipoPreparacaoPlanejamentoTemp);
              }
            }

            if (planejamentoTemp?.materialDiverso) {
              if (!tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento) {
                tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento = new Array<ClasseTipoPreparacaoPlanejamento>();
              }
              // tslint:disable-next-line: max-line-length
              const classeNaLista = tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento.find(x => x.id === (planejamentoTemp.materialDiverso && planejamentoTemp.materialDiverso.classeMaterialDiverso.id));
              if (!classeNaLista && planejamentoTemp.materialDiverso) {
                const classeTipoPreparacaoPlanejamentoTemp = new ClasseTipoPreparacaoPlanejamento();
                classeTipoPreparacaoPlanejamentoTemp.id = planejamentoTemp.materialDiverso.classeMaterialDiverso.id;
                classeTipoPreparacaoPlanejamentoTemp.nome = planejamentoTemp.materialDiverso.classeMaterialDiverso.nome;
                classeTipoPreparacaoPlanejamentoTemp.tipo = 'Classe Material Diverso';
                classeTipoPreparacaoPlanejamentoTemp.dataPlanejamento = new Array<DataPlanejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento = new Array<Planejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento.push(planejamentoTemp);
                tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento.push(classeTipoPreparacaoPlanejamentoTemp);
              }
            }

            if (planejamentoTemp?.fichaTecnica) {
              if (!tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento) {
                tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento = new Array<ClasseTipoPreparacaoPlanejamento>();
              }
              // tslint:disable-next-line: max-line-length
              const tipoPreparacaoNaLista = tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento.find(x => x.id === (planejamentoTemp.fichaTecnica && planejamentoTemp.fichaTecnica.tipoPreparacao.id));
              if (!tipoPreparacaoNaLista && planejamentoTemp.fichaTecnica) {
                const classeTipoPreparacaoPlanejamentoTemp = new ClasseTipoPreparacaoPlanejamento();
                classeTipoPreparacaoPlanejamentoTemp.id = planejamentoTemp.fichaTecnica.tipoPreparacao.id;
                classeTipoPreparacaoPlanejamentoTemp.nome = planejamentoTemp.fichaTecnica.tipoPreparacao.nome;
                classeTipoPreparacaoPlanejamentoTemp.tipo = 'Tipo Preparação';
                classeTipoPreparacaoPlanejamentoTemp.dataPlanejamento = new Array<DataPlanejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento = new Array<Planejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento.push(planejamentoTemp);
                tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento.push(classeTipoPreparacaoPlanejamentoTemp);
              }
            }

            if (planejamentoTemp?.produto) {
              if (!tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento) {
                tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento = new Array<ClasseTipoPreparacaoPlanejamento>();
              }
              // tslint:disable-next-line: max-line-length
              const classeNaLista = tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento.find(x => x.nome === 'Produto');
              if (!classeNaLista && planejamentoTemp.produto) {
                const classeTipoPreparacaoPlanejamentoTemp = new ClasseTipoPreparacaoPlanejamento();
                classeTipoPreparacaoPlanejamentoTemp.id = null;
                classeTipoPreparacaoPlanejamentoTemp.nome = 'Produto';
                classeTipoPreparacaoPlanejamentoTemp.tipo = 'Produto';
                classeTipoPreparacaoPlanejamentoTemp.dataPlanejamento = new Array<DataPlanejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento = new Array<Planejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento.push(planejamentoTemp);
                tipoRefeicaoPlanjamento.classeTipoPreparacaoPlanejamento.push(classeTipoPreparacaoPlanejamentoTemp);
              }
            }

            this.listaTipoRefeicao.push(tipoRefeicaoPlanjamento);

          } else {

            if (planejamentoTemp?.alimento) {
              if (!tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento) {
                tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento = new Array<ClasseTipoPreparacaoPlanejamento>();
              }
              // tslint:disable-next-line: max-line-length
              const classeNaLista = tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento.find(x => x.id === (planejamentoTemp.alimento && planejamentoTemp.alimento.classe.id));
              if (!classeNaLista && planejamentoTemp.alimento) {
                const classeTipoPreparacaoPlanejamentoTemp = new ClasseTipoPreparacaoPlanejamento();
                classeTipoPreparacaoPlanejamentoTemp.id = planejamentoTemp.alimento.classe.id;
                classeTipoPreparacaoPlanejamentoTemp.nome = planejamentoTemp.alimento.classe.nome;
                classeTipoPreparacaoPlanejamentoTemp.tipo = 'Classe Alimento';
                classeTipoPreparacaoPlanejamentoTemp.dataPlanejamento = new Array<DataPlanejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento = new Array<Planejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento.push(planejamentoTemp);
                tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento.push(classeTipoPreparacaoPlanejamentoTemp);
              } else {
                classeNaLista.planejamento.push(planejamentoTemp);
              }
            }

            if (planejamentoTemp?.materialDiverso) {
              if (!tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento) {
                tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento = new Array<ClasseTipoPreparacaoPlanejamento>();
              }
              // tslint:disable-next-line: max-line-length
              const classeNaLista = tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento.find(x => x.id === (planejamentoTemp.materialDiverso && planejamentoTemp.materialDiverso.classeMaterialDiverso.id));
              if (!classeNaLista && planejamentoTemp.materialDiverso) {
                const classeTipoPreparacaoPlanejamentoTemp = new ClasseTipoPreparacaoPlanejamento();
                classeTipoPreparacaoPlanejamentoTemp.id = planejamentoTemp.materialDiverso.classeMaterialDiverso.id;
                classeTipoPreparacaoPlanejamentoTemp.nome = planejamentoTemp.materialDiverso.classeMaterialDiverso.nome;
                classeTipoPreparacaoPlanejamentoTemp.tipo = 'Classe Material Diverso';
                classeTipoPreparacaoPlanejamentoTemp.dataPlanejamento = new Array<DataPlanejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento = new Array<Planejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento.push(planejamentoTemp);
                tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento.push(classeTipoPreparacaoPlanejamentoTemp);
              } else {

                classeNaLista.planejamento.push(planejamentoTemp);

              }
            }

            if (planejamentoTemp?.fichaTecnica) {

              if (!tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento) {
                tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento = new Array<ClasseTipoPreparacaoPlanejamento>();
              }
              // tslint:disable-next-line: max-line-length
              const tipoPreparacaoNaLista = tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento.find(x => x.id === (planejamentoTemp.fichaTecnica && planejamentoTemp.fichaTecnica.tipoPreparacao.id));
              if (!tipoPreparacaoNaLista && planejamentoTemp.fichaTecnica) {
                const classeTipoPreparacaoPlanejamentoTemp = new ClasseTipoPreparacaoPlanejamento();
                classeTipoPreparacaoPlanejamentoTemp.id = planejamentoTemp.fichaTecnica.tipoPreparacao.id;
                classeTipoPreparacaoPlanejamentoTemp.nome = planejamentoTemp.fichaTecnica.tipoPreparacao.nome;
                classeTipoPreparacaoPlanejamentoTemp.tipo = 'Tipo Preparação';
                classeTipoPreparacaoPlanejamentoTemp.dataPlanejamento = new Array<DataPlanejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento = new Array<Planejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento.push(planejamentoTemp);
                tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento.push(classeTipoPreparacaoPlanejamentoTemp);
              } else {

                tipoRefeicaoNaLista?.planejamento?.push(planejamentoTemp);

              }
            }

            if (planejamentoTemp?.produto) {
              if (!tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento) {
                tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento = new Array<ClasseTipoPreparacaoPlanejamento>();
              }
              // tslint:disable-next-line: max-line-length
              const classeNaLista = tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento.find(x => x.nome === 'Produto');
              if (!classeNaLista && planejamentoTemp.produto) {
                const classeTipoPreparacaoPlanejamentoTemp = new ClasseTipoPreparacaoPlanejamento();
                classeTipoPreparacaoPlanejamentoTemp.id = null;
                classeTipoPreparacaoPlanejamentoTemp.nome = 'Produto';
                classeTipoPreparacaoPlanejamentoTemp.tipo = 'Produto';
                classeTipoPreparacaoPlanejamentoTemp.dataPlanejamento = new Array<DataPlanejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento = new Array<Planejamento>();
                classeTipoPreparacaoPlanejamentoTemp.planejamento.push(planejamentoTemp);
                tipoRefeicaoNaLista.classeTipoPreparacaoPlanejamento.push(classeTipoPreparacaoPlanejamentoTemp);
              } else {

                tipoRefeicaoNaLista?.planejamento?.push(planejamentoTemp);

              }
            }
          }
        });

        this.blockUI.stop();
      });
  }


  redirecionarMapaProducao() {
    this.router.navigateByUrl('/mapa-producao', { state: { mapaProducaoId: this.mapaProducaoSelecionado.id } });
  }

  nomeReduzido(nome: string, caracteres = 16): string {
    let retorno = '';

    if (nome.length > caracteres) {
      retorno = nome.substring(0, caracteres) + '...';
    } else {
      retorno = nome;
    }
    return retorno;
  }

  podeExibirModuloPlano(moduloPlano: string[]) {
    return this.permissoesService.podeExibirModuloPlano(moduloPlano);
  }
}
