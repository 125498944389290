import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import locatePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { MenuUsuarioComponent } from './core/menu-usuario/menu-usuario.component';
import { MenuEmpresaComponent } from './core/menu-empresa/menu-empresa.component';
import { MenuComponent } from './core/menu/menu.component';
import { HomeComponent } from './modules/home/home.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { MenuAdminComponent } from './core/header/menu-admin/menu-admin.component';
import { MenuUsuarioAdminComponent } from './core/menu-usuario-admin/menu-usuario-admin.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { JwtInterceptor } from './core/auth/jwtinterceptor';
import { ModalOnboardHomeComponent } from './core/onboard/modal-onboard-home/modal-onboard-home.component';
import { ModalBloqueioPlanoComponent } from './shared/modal-bloqueio-plano/modal-bloqueio-plano.component';
import { NgxTreeDndModule } from 'ngx-tree-dnd';
import { NotificacaoComponent } from './core/notificacao/notificacao.component';
import { IntercomModule } from 'ng-intercom';

registerLocaleData(locatePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    NotFoundComponent,
    MenuUsuarioComponent,
    MenuEmpresaComponent,
    MenuComponent,
    NotFoundComponent,
    MenuAdminComponent,
    MenuUsuarioAdminComponent,
    SidebarComponent,
    ModalOnboardHomeComponent,
    ModalBloqueioPlanoComponent,
    NotificacaoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    NgbModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    NgxTreeDndModule,
    IntercomModule.forRoot({
      appId: 'myjflzle', // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    })
  ],
  exports: [],
  providers: [
    BsModalRef,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
