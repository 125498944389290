import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/core/services/login.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { UsuarioEmpresa } from 'src/app/shared/models/usuarioEmpresa';
import constants from '../config/constants';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LocalStorageObject } from '../services/localStorageObject.service';
import { PermissoesService } from '../auth/permissoes.service';
import { AuthService } from '../auth/auth.service';
import { AuthGuard } from '../authentication/auth.guard';
import { AdminService } from '../services/admin.service';
import { ListaPaginadaUsuarioEmpresa } from 'src/app/shared/models/listas-paginadas/listaPaginadaUsuariosEmpresa';
import { FiltroUsuarioEmpresa } from 'src/app/shared/models/filtros/filtroUsuarioEmpresa';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { UsuarioEmpresaService } from '../services/usuarioEmpresa.service';
import { UtilService } from '../services/util.service';

@Component({
  selector: 'app-menu-usuario',
  templateUrl: './menu-usuario.component.html',
  styleUrls: ['./menu-usuario.component.scss']
})
export class MenuUsuarioComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  @ViewChild('staticModalEscolherEmpresa', { static: true }) staticModalEscolherEmpresa: ModalDirective;

  token: string;
  expira: string;
  listaUsuarioEmpresa: ListaPaginadaUsuarioEmpresa;
  modulos: any;
  mensagem: string;
  usuarioSuperAdmin: boolean;
  filtroUsuarioEmpresa: FiltroUsuarioEmpresa;
  nome: string;

  config = {
    backdrop: false,
    ignoreBackdropClick: true
  };

  constructor(
    public readonly authService: AuthService,
    private readonly toastr: ToastrService,
    private readonly loginService: LoginService,
    public readonly localStorageObject: LocalStorageObject,
    private readonly permissoesService: PermissoesService,
    private readonly authGuard: AuthGuard,
    private readonly adminService: AdminService,
    private readonly usuarioEmpresaService: UsuarioEmpresaService,
    private readonly utilService: UtilService
  ) {
    this.modulos = constants.MODULOS;
    this.listaUsuarioEmpresa = new ListaPaginadaUsuarioEmpresa();
    this.filtroUsuarioEmpresa = new FiltroUsuarioEmpresa();
  }

  ngOnInit() { }

  usuarioComFoto(): boolean {
    let retorno = true;
    if (this.localStorageObject.usuario.urlFoto) {
      retorno = false;
    }
    return retorno;
  }

  selecionarEmpresa(usuarioEmpresa: UsuarioEmpresa): void {
    if (this.superAdmin()) {
      localStorage.setItem('rotulagem.empresaSelecionada', JSON.stringify(usuarioEmpresa.empresa));
      this.localStorageObject.popularVariavelGlobalEmpresa();

      this.fecharModalTrocarEmpresa();
      this.authGuard.redirecionarRotaRaizPermissao(this.localStorageObject.usuario, this.localStorageObject.empresaSelecionada);
      return;
    }

    this.blockUI.start();

    this.loginService.tokenPermissoesEmpresa(this.localStorageObject.usuario.id, usuarioEmpresa.id).subscribe(responseTokenPermissoesEmpresa => {

        // Plano expirado
        const dataAtual = new Date().getTime();
        const dataTerminoDate = new Date(usuarioEmpresa.empresa.planoEmpresa.dataTermino);
        const dataTermino = dataTerminoDate.setDate(dataTerminoDate.getDate() + 1);

        if (!usuarioEmpresa.empresa.planoEmpresa.plano.planoBase && (dataAtual > dataTermino)) {

          this.adminService.editarClientePlanoBase(usuarioEmpresa.empresa.id).subscribe(response => {
            usuarioEmpresa.empresa = response;
            localStorage.setItem('rotulagem.empresaSelecionada', JSON.stringify(usuarioEmpresa.empresa));
            this.carregarTelaInicial(responseTokenPermissoesEmpresa);
          });

        } else {
          localStorage.setItem('rotulagem.empresaSelecionada', JSON.stringify(usuarioEmpresa.empresa));
          this.carregarTelaInicial(responseTokenPermissoesEmpresa);
        }
      },
      falhou => {
        this.blockUI.stop();
        console.error(falhou);
        this.toastr.error('Não foi possível trocar de empresa.');
      }
    );
  }

  carregarTelaInicial(response: any): void {
    localStorage.setItem('rotulagem.token', response.access_token);
    localStorage.setItem('rotulagem.expira', response.expires_in);

    this.localStorageObject.usuario.permissoes = response.permissoes;
    this.localStorageObject.usuario.perfilId = response.perfilId;
    this.localStorageObject.usuario.perfilNome = response.perfilNome;
    this.localStorageObject.usuario.superAdmin = response.superAdmin;
    localStorage.setItem('rotulagem.usuario', JSON.stringify(this.localStorageObject.usuario));

    this.localStorageObject.popularVariavelGlobalEmpresa();
    this.localStorageObject.popularVariavelGlobalUsuario();

    this.fecharModalTrocarEmpresa();
    this.blockUI.stop();
    this.authGuard.redirecionarRotaRaizPermissao(this.localStorageObject.usuario, this.localStorageObject.empresaSelecionada);
  }

  podeExibirModulo(moduloId: string | number): boolean {
    return this.permissoesService.podeExibirModulo(moduloId);
  }

  abrirModalListaEmpresa(): void {
    this.filtroUsuarioEmpresa.pagina = 1;
    this.filtroUsuarioEmpresa.tamanhoPagina = 10;
    this.obterListaEmpresa();
  }

  obterListaEmpresa(): void {
    this.blockUI.start();
    this.filtroUsuarioEmpresa.usuarioId = this.localStorageObject.usuario.id;
    this.usuarioEmpresaService.obterListaPaginadaEmpresasDoUsuario(this.filtroUsuarioEmpresa).subscribe((listaPaginadaUsuariosDaEmpresa) => {
      this.localStorageObject.usuario.usuarioEmpresa = listaPaginadaUsuariosDaEmpresa.itens;
      this.utilService.setCache('rotulagem.usuario', this.localStorageObject.usuario);

      this.localStorageObject.popularVariavelGlobalEmpresa();
      this.localStorageObject.popularVariavelGlobalUsuario();

      if (this.localStorageObject.usuario) {
        this.listaUsuarioEmpresa = listaPaginadaUsuariosDaEmpresa;
      }
      this.staticModalEscolherEmpresa.show();
      this.blockUI.stop();
    });
  }

  superAdmin(): boolean {
    const superAdmin = !!this.localStorageObject.usuario.superAdmin;
    return superAdmin;
  }

  fecharModalTrocarEmpresa(): void {
    this.staticModalEscolherEmpresa.hide();
  }

  buscarPorNome(): void {
    this.filtroUsuarioEmpresa.nome = this.nome;
    this.obterListaEmpresa();
  }

  alterarPagina(event: PageChangedEvent): void {
    this.filtroUsuarioEmpresa.pagina = event.page;
    this.filtroUsuarioEmpresa.tamanhoPagina = event.itemsPerPage;
    this.obterListaEmpresa();
  }

  logout(): void {
    this.authService.logout();
  }

}
