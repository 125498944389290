import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../utils/index';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  // tslint:disable-next-line: max-line-length
  transform(value: any, unidadeMedida: any, digits: any, sufix: any, prefix: any, skipZero: any, qtdNaoSignificativa: any, emptyDesc: any, signed: any): any {
    if (emptyDesc === undefined) {
      emptyDesc = '-';
    }

    if (qtdNaoSignificativa && value <= qtdNaoSignificativa) {
      return emptyDesc;
    }

    if (value === undefined) {
      return emptyDesc;
    }

    // tslint:disable-next-line: triple-equals
    if (digits == 'regra-rotulo') {

      digits = (value < 10) ? 1 : 0;
    }

    let fixed;
    if (digits === 0) {
      fixed = parseFloat(value).toFixed(digits);
    } else {
      fixed = parseFloat(value).toFixed((digits || 2));
    }

    if (skipZero && parseFloat(fixed) === 0) {
      return emptyDesc;
    }

    let v = Utils.decimalMask(fixed, digits, ',', '.');

    if (!v) {
      return emptyDesc;
    }

    if (signed && fixed.startsWith('-')) {
      v = '-' + v;
    }

    return (prefix || '') + v + (sufix || '');
  }

}
