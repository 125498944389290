import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { FiltroDashboard } from 'src/app/shared/models/filtroDashboard';
import { Observable } from 'rxjs';
import { ItemInventario } from 'src/app/shared/models/itemInventario';
import { OrdemSaida } from 'src/app/modules/estoque/ordem-saida/models/ordemSaida';
import { FiltroOrdemSaida } from 'src/app/shared/models/filtros/filtroOrdemSaida';
import { MapaProducao } from 'src/app/shared/models/mapaProducao';
import { FiltroMapaProducao } from 'src/app/shared/models/filtros/filtroMapaProducao';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  obterItensEstoqueMinimo(filtroDashboard: FiltroDashboard): Observable<ItemInventario[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.post<ItemInventario[]>(this.urlService + 'dashboard/obterItensEstoqueMinimo', filtroDashboard, super.ObterAuthHeaderJson());
  }

  obterItensEstoqueProximoValidade(filtroDashboard: FiltroDashboard) {
    return this.http.post(this.urlService + 'dashboard/obterItensEstoqueProximoValidade', filtroDashboard, super.ObterAuthHeaderJson());
  }

  obterListasDeComprasPendentes(filtroDashboard: FiltroDashboard) {
    return this.http.post(this.urlService + 'dashboard/obterListasDeComprasPendentes', filtroDashboard, super.ObterAuthHeaderJson());
  }

  obterItensOrdemSaida(filtroOrdemSaida: FiltroOrdemSaida): Observable<OrdemSaida> {
    return this.http.post<OrdemSaida>(this.urlService + 'dashboard/obterItensOrdemSaida', filtroOrdemSaida, super.ObterAuthHeaderJson());
  }

  obterListaMapaProducao(filtroMapaProducao: FiltroMapaProducao): Observable<MapaProducao[]> {
    return this.http.post<MapaProducao[]>(this.urlService + 'dashboard/obterListaMapaProducao', filtroMapaProducao, super.ObterAuthHeaderJson());
  }

}
