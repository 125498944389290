import { Component, Input, OnInit } from '@angular/core';
import { PlanoEmpresa } from 'src/app/shared/models/planoEmpresa';
import { LocalStorageObject } from '../services/localStorageObject.service';

@Component({
  selector: 'app-menu-empresa',
  templateUrl: './menu-empresa.component.html',
  styleUrls: ['./menu-empresa.component.scss']
})
export class MenuEmpresaComponent implements OnInit {

  @Input() logoEmpresa: string;

  planoEmpresa: PlanoEmpresa;

  constructor(
    public readonly localStorageObject: LocalStorageObject,
    ) {
    this.planoEmpresa = new PlanoEmpresa();
  }

  ngOnInit() {
    this.planoEmpresa = this.localStorageObject?.empresaSelecionada?.planoEmpresa;
  }
}
