import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FiltroUsuarioEmpresa } from 'src/app/shared/models/filtros/filtroUsuarioEmpresa';
import { ListaPaginadaUsuariosDaEmpresa } from 'src/app/shared/models/listas-paginadas/listaPaginadaUsuariosDaEmpresa';
import { UsuarioDaEmpresa } from 'src/app/shared/models/usuarioDaEmpresa';
import { UsuarioEmpresa } from 'src/app/shared/models/usuarioEmpresa';
import { ListaPaginadaEmpresa } from 'src/app/shared/models/listas-paginadas/listaPaginadaEmpresa';
import { ListaPaginadaUsuarioEmpresa } from 'src/app/shared/models/listas-paginadas/listaPaginadaUsuariosEmpresa';

@Injectable({
  providedIn: 'root'
})
export class UsuarioEmpresaService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  obterListaPaginadaEmpresasDoUsuario(filtro: FiltroUsuarioEmpresa): Observable<ListaPaginadaUsuarioEmpresa> {
    return this.http.post<ListaPaginadaUsuarioEmpresa>(this.urlService + 'usuarioEmpresa/ObterListaPaginadaEmpresasDoUsuario', filtro, super.ObterAuthHeaderJson());
  }

  obterListaPaginadaUsuariosDaEmpresa(filtro: FiltroUsuarioEmpresa): Observable<ListaPaginadaUsuariosDaEmpresa> {
    return this.http.post<ListaPaginadaUsuariosDaEmpresa>(this.urlService + 'usuarioEmpresa/ObterListaPaginadaUsuariosDaEmpresa', filtro, super.ObterAuthHeaderJson());
  }

  obterListaUsuariosDaEmpresa(filtro: FiltroUsuarioEmpresa): Observable<UsuarioEmpresa[]> {
    return this.http.post<UsuarioEmpresa[]>(this.urlService + 'usuarioEmpresa/obterListaUsuariosDaEmpresa', filtro, super.ObterAuthHeaderJson());
  }

  incluir(membro: UsuarioDaEmpresa) {
    return this.http.post<UsuarioDaEmpresa>(this.urlService + 'usuarioEmpresa/IncluirMembroEmpresa', membro, super.ObterAuthHeaderJson());
  }

  alterar(membro: UsuarioDaEmpresa) {
    return this.http.put<UsuarioDaEmpresa>(this.urlService + 'usuarioEmpresa/Alterar', membro, super.ObterAuthHeaderJson());
  }

  excluir(id: string): Observable<any> {
    return this.http.delete(this.urlService + 'usuarioEmpresa/excluir?id=' + id, super.ObterAuthHeaderJson());
  }

}

