

const pageMenuAdmin = [
  {
    icon: 'fa fa-arrow-left',
    title: 'Voltar Menu Principal',
    url: '/dashboard'
  },
  {
    icon: 'fa fa-users',
    title: 'Clientes',
    url: 'admin/clientes/lista-clientes'
  },
  {
    icon: 'fa fa-box',
    title: 'Planos',
    url: 'admin/planos/lista-planos'
  }
];


export default pageMenuAdmin;
