import { Injectable } from '@angular/core';
import { LocalStorageObject } from 'src/app/core/services/localStorageObject.service';
import { Planejamento } from 'src/app/shared/models/planejamento';
import { ProdutoItem } from 'src/app/shared/models/produtoItem';

@Injectable({
  providedIn: 'root'
})
export class MapaProducaoHelperService {

  constructor(
    private readonly localStorageObject: LocalStorageObject,
    ) {
  }

  today() {
    return this.dateOnly(new Date());
  }

  beginningOfPeriod(d, periodUom, startDow) {
    switch (periodUom) {
      case 'year':
        return new Date(d.getFullYear(), 0);
      case 'month':
        return new Date(d.getFullYear(), d.getMonth());
      case 'week':
        return this.beginningOfWeek(d, startDow);
      default:
        return null;
    }
  }

  daysOfWeek(weekStart: any): Date[] {
    return Array(7)
      .fill('')
      .map((_, i: number) => this.addDays(weekStart, i));
  }

  addDays(d: any, days: number) {
    return new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate() + days,
      d.getHours(),
      d.getMinutes(),
      d.getSeconds()
    );
  }

  supportsIntl() {
    return typeof Intl !== 'undefined';
  }

  // Nome dias da semana formatado - Inicio

  getFormattedWeekdayNames(locale: string, format: any, startingDayOfWeek: number) {
    if (!this.supportsIntl()) {
      return Array(7).fill('');
    }

    const formatter = new Intl.DateTimeFormat(locale, { weekday: format });
    return Array(7)
      .fill('')
      .map((_, i) =>
        formatter.format(new Date(2017, 0, (i + 1 + startingDayOfWeek) % 7))
      );
  }

  // Nome dias da semana formatado - Fim

  getDefaultBrowserLocale() {
    if (typeof navigator === 'undefined') { return 'unk'; }

    return (navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language
    ).toLowerCase();
  }

  beginningOfWeek(d, startDow) {
    return this.addDays(d, (startDow - d.getDay() - 7) % -7);
  }

  endOfWeek(d, startDow) {
    return this.addDays(this.beginningOfWeek(d, startDow), 7);
  }

  dateOnly(d) {

    const d2 = new Date(d);
    d2.setHours(0, 0, 0, 0);
    return d2;
  }

  incrementPeriod(d: Date, uom: string, count: number) {
    return new Date(
      d.getFullYear() + (uom === 'year' ? count : 0),
      d.getMonth() + (uom === 'month' ? count : 0),
      d.getDate() + (uom === 'week' ? count * 7 : 0)
    );
  }

  dayDiff(d1: any, d2: any) {
    const startDate = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
    const endDate = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());
    endDate.setUTCHours(0, 0, 0, 0);
    startDate.setUTCHours(0, 0, 0, 0);
    return (endDate.getTime() - startDate.getTime()) / 86400000;
  }



  isSameMonth(d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth()
    );
  }

  getFormattedMonthNames(locale, format) {

    if (!this.supportsIntl()) { return Array(12).fill(''); }
    const formatter = new Intl.DateTimeFormat(locale, { month: format });

    return Array(12)
      .fill('')
      .map((_, i) => formatter.format(new Date(2017, i, 1)));
  }

  formattedPeriod(startDate: Date, endDate: Date, periodUom: string, monthNames: Array<string>) {
    const singleYear = startDate.getFullYear() === endDate.getFullYear();
    const singleMonth = this.isSameMonth(startDate, endDate);
    const isYear = periodUom === 'year';
    const isMonth = periodUom === 'month';
    const isWeek = !isYear && !isMonth;
    const s = [];

    if (singleMonth && singleYear) {
      s.push(startDate.getDate());
      s.push(' \u2013 ');
      s.push(endDate.getDate());
      s.push(', ');
      s.push(monthNames[startDate.getMonth()]);
      s.push(' ');
      s.push(endDate.getFullYear());
    }

    if (!singleMonth && singleYear) {
      s.push(startDate.getDate());
      s.push(', ');
      s.push(monthNames[startDate.getMonth()]);
      s.push(' até ');
      s.push(endDate.getDate());
      s.push(', ');
      s.push(monthNames[endDate.getMonth()]);
      s.push(' ');
      s.push(endDate.getFullYear());
    }

    if (!singleMonth && !singleYear) {
      s.push(startDate.getDate());
      s.push(', ');
      s.push(monthNames[startDate.getMonth()]);
      s.push(' ');
      s.push(startDate.getFullYear());
      s.push(' até ');
      s.push(endDate.getDate());
      s.push(', ');
      s.push(monthNames[endDate.getMonth()]);
      s.push(' ');
      s.push(endDate.getFullYear());
    }

    return s.join('');
  }

  nomeItemPlanejamento(planejamento: Planejamento): string {
    let response = '';

    if (planejamento?.alimento) {
      response = planejamento?.alimento?.nome;
    }

    if (planejamento?.materialDiverso) {
      response = planejamento?.materialDiverso?.nome;
    }

    if (planejamento?.fichaTecnica) {
      response = planejamento?.fichaTecnica?.nome;
    }

    if (planejamento?.produto) {
      response = planejamento?.produto?.nome;
    }

    return response;
  }

  nomeClassePlanejamento(planejamento: Planejamento, tipoVisivel?: boolean): string {
    let response = '';

    if (planejamento?.alimento?.classe) {
      response = `${planejamento?.alimento?.classe?.nome} ${tipoVisivel ? '(Classe Alimento)' : ''}`;
    }

    if (planejamento?.materialDiverso?.classeMaterialDiverso) {
      response = `${planejamento?.materialDiverso?.classeMaterialDiverso?.nome} ${tipoVisivel ? '(Classe Material Diverso)' : ''}`;
    }

    if (planejamento?.fichaTecnica?.tipoPreparacao) {
      response = `${planejamento?.fichaTecnica?.tipoPreparacao?.nome} ${tipoVisivel ? '(Tipo Preparação)' : ''}`;
    }

    if (planejamento?.produtoId) {
      response = `Produto`;
    }

    return response;
  }

  formatarTelefone(v: string) {
    let response = '';
    if (v) {
      let r = v.replace(/\D/g, '');
      r = r.replace(/^0/, '');
      if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
      } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
      } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
      } else {
        r = r.replace(/^(\d*)/, '($1');
      }
      response = r;
    }
    return response;
  }

  // Calculos

  calcularCustoPercapita(planejamento: Planejamento, filtroTipoValor: boolean): number {
    let response = 0;

    if (planejamento?.fichaTecnica) {

      if (planejamento.pesoRendimento > 0) {
        if (planejamento.numeroComensais > 0) {
          response = ((this.converterParaGrama(planejamento.pesoRendimento, planejamento.unidadePesoRendimento) /
            this.converterParaGrama(planejamento.fichaTecnica.rendimentoTotal, planejamento.fichaTecnica.unidadeRendimentoTotal)) *
            (planejamento.fichaTecnica.custoTotal * planejamento.fichaTecnica.numeroPorcao)) / planejamento.numeroComensais;
        } else {
          response = 0;
        }
      }

      if (planejamento.numeroPorcoes > 0 && !planejamento.pesoRendimento && !planejamento.pesoPerCapita) {
        if (planejamento.fichaTecnica) {
          response = (planejamento.numeroComensais * planejamento.fichaTecnica.custoTotal) / planejamento.numeroComensais;
        }
      }

      if (planejamento.pesoPerCapita > 0) {
        response = (planejamento.fichaTecnica.custoTotal * this.converterParaGrama(planejamento.pesoPerCapita, planejamento.unidadePesoPerCapita)) /
          this.converterParaGrama(planejamento.fichaTecnica.quantidadePorcao, planejamento.fichaTecnica.unidadeQuantidadePorcao);
      }

      if (planejamento.numeroComensais === 0) {
        response = 0;
      } else {
        if (filtroTipoValor) {
          response = response * planejamento.numeroComensais;
        }
      }

    }

    if (planejamento?.alimento) {
      if (planejamento.pesoRendimento > 0) {
        response = this.truncarValor(((this.localStorageObject?.empresaSelecionada?.custoMedioAtivo ? planejamento?.alimento?.custoMedio : planejamento?.alimento?.precoQuilo) *
        this.converterParaGrama(planejamento.pesoRendimento, planejamento.unidadePesoRendimento)) / 1000);
        if (planejamento.numeroComensais === 0) {
          response = 0;
        } else {
          if (filtroTipoValor) {
            response = response;
          } else {
            response = response / planejamento.numeroComensais;
          }
        }
      }

      if (planejamento.pesoPerCapita > 0) {
        response = this.truncarValor(((this.localStorageObject?.empresaSelecionada?.custoMedioAtivo ? planejamento?.alimento?.custoMedio : planejamento?.alimento?.precoQuilo) *
        this.converterParaGrama(planejamento.pesoPerCapita, planejamento.unidadePesoPerCapita)) / 1000);
        if (planejamento.numeroComensais === 0) {
          response = 0;
        } else {
          if (filtroTipoValor) {
            response = response;
          } else {
            response = response / planejamento.numeroComensais;
          }
        }
      }
    }

    if (planejamento?.materialDiverso) {
      // tslint:disable-next-line: max-line-length
      response = (this.localStorageObject?.empresaSelecionada?.custoMedioAtivo ? planejamento?.materialDiverso?.custoMedio : planejamento?.materialDiverso?.precoUnidade) * planejamento.quantidadeMaterialDiverso;
      if (planejamento.numeroComensais === 0) {
        response = 0;
      } else {
        if (filtroTipoValor) {
          response = response;
        } else {
          response = response / planejamento.numeroComensais;
        }
      }
    }

    if (planejamento?.produto?.produtoItem) {
      planejamento?.produto?.produtoItem.forEach(produtoItem => {

        if (produtoItem?.fichaTecnica) {

          if (produtoItem.pesoRendimento > 0) {
            if (planejamento.numeroComensais > 0) {
              response = ((this.converterParaGrama(produtoItem.pesoRendimento, produtoItem.unidadePesoRendimento) /
                this.converterParaGrama(produtoItem.fichaTecnica.rendimentoTotal, produtoItem.fichaTecnica.unidadeRendimentoTotal)) *
                (produtoItem.fichaTecnica.custoTotal * produtoItem.fichaTecnica.numeroPorcao)) / planejamento.numeroComensais;
            } else {
              response = 0;
            }
          }

          if (produtoItem.numeroPorcoes > 0 && !produtoItem.pesoRendimento && !produtoItem.pesoPerCapita) {
            if (produtoItem.fichaTecnica) {
              response = (planejamento.numeroComensais * produtoItem.fichaTecnica.custoTotal) / planejamento.numeroComensais;
            }
          }

          if (produtoItem.pesoPerCapita > 0) {
            response = (produtoItem.fichaTecnica.custoTotal * this.converterParaGrama(produtoItem.pesoPerCapita, produtoItem.unidadePesoPerCapita)) /
              this.converterParaGrama(produtoItem.fichaTecnica.quantidadePorcao, produtoItem.fichaTecnica.unidadeQuantidadePorcao);
          }

          if (planejamento.numeroComensais === 0) {
            response = 0;
          } else {
            if (filtroTipoValor) {
              response = response * planejamento.numeroComensais;
            }
          }

        }

        if (produtoItem?.alimento) {
          response = this.truncarValor(((this.localStorageObject?.empresaSelecionada?.custoMedioAtivo ? produtoItem?.alimento?.custoMedio : produtoItem?.alimento?.precoQuilo) *
            this.converterParaGrama(produtoItem.pesoRendimento, produtoItem.unidadePesoRendimento)) / 1000);
          if (planejamento.numeroComensais === 0) {
            response = 0;
          } else {
            if (filtroTipoValor) {
              response = response;
            } else {
              response = response / planejamento.numeroComensais;
            }
          }
        }

        if (produtoItem?.materialDiverso) {
          // tslint:disable-next-line: max-line-length
          response = (this.localStorageObject?.empresaSelecionada?.custoMedioAtivo ? produtoItem?.materialDiverso?.custoMedio : produtoItem?.materialDiverso?.precoUnidade) * produtoItem.quantidadeMaterialDiverso;
          if (planejamento.numeroComensais === 0) {
            response = 0;
          } else {
            if (filtroTipoValor) {
              response = response;
            } else {
              response = response / planejamento.numeroComensais;
            }
          }
        }
      });
    }

    if (response > 0) {
      return response;
    } else {
      return 0;
    }
  }

  calcularSomaCustoTotalPercapita(listaPlanejamentoTemp: Planejamento[]) {
    let response = 0;

    listaPlanejamentoTemp.forEach(planejamentoTemp => {
      const calculo = this.calcularCustoPercapita(planejamentoTemp, false);
      if (calculo > 0) {
        response += calculo;
      }
    });

    return response;
  }

  calcularRendimento(planejamento: Planejamento): number {
    let response = 0;

    if (planejamento.pesoRendimento > 0) {
      response = this.converterParaGrama(planejamento.pesoRendimento, planejamento.unidadePesoRendimento);
    }

    if (planejamento.pesoPerCapita > 0) {
      response = this.converterParaGrama(planejamento.pesoPerCapita, planejamento.unidadePesoPerCapita) * planejamento.numeroComensais;
    }

    if (planejamento.numeroPorcoes > 0 && !planejamento.pesoRendimento && !planejamento.pesoPerCapita) {
      if (planejamento.fichaTecnica) {
        response = this.converterParaGrama(planejamento.fichaTecnica.quantidadePorcao, planejamento.fichaTecnica.unidadeQuantidadePorcao) * planejamento.numeroComensais;
      }
    }

    return response;
  }

  calcularRendimentoProdutoItem(produtoItem: ProdutoItem, numeroComensais: number): number {
    let response = 0;

    if (produtoItem.pesoRendimento > 0) {
      response = this.converterParaGrama(produtoItem.pesoRendimento, produtoItem.unidadePesoRendimento) * numeroComensais;
    }

    if (produtoItem.pesoPerCapita > 0) {
      response = this.converterParaGrama(produtoItem.pesoPerCapita, produtoItem.unidadePesoPerCapita) * numeroComensais;
    }

    if (produtoItem.numeroPorcoes > 0 && !produtoItem.pesoRendimento && !produtoItem.pesoPerCapita) {
      if (produtoItem.fichaTecnica) {
        response = this.converterParaGrama(produtoItem.fichaTecnica.quantidadePorcao, produtoItem.fichaTecnica.unidadeQuantidadePorcao) * numeroComensais;
      }
    }

    return response;
  }

  calcularPerCapita(planejamento: Planejamento): number {
    let response = 0;

    if (planejamento?.pesoRendimento > 0) {
      response = this.converterParaGrama(planejamento.pesoRendimento, planejamento.unidadePesoRendimento) / planejamento.numeroComensais;
    }

    if (planejamento?.pesoPerCapita > 0) {
      response = this.converterParaGrama(planejamento.pesoPerCapita, planejamento.unidadePesoPerCapita);
    }

    if (planejamento?.numeroPorcoes > 0 && !planejamento?.pesoRendimento && !planejamento?.pesoPerCapita) {
      if (planejamento?.fichaTecnica) {
        response = this.converterParaGrama(planejamento.fichaTecnica.quantidadePorcao, planejamento.fichaTecnica.unidadeQuantidadePorcao);
      }
    }

    if (planejamento?.numeroComensais > 0 && planejamento?.materialDiverso) {
      if (planejamento?.quantidadeMaterialDiverso > 0 && planejamento?.unidadeMaterialDiverso) {
        response = planejamento?.quantidadeMaterialDiverso / planejamento.numeroComensais;
      }

      if (planejamento?.quantidadeMaterialDiversoPerCapita > 0 && planejamento?.unidadeMaterialDiversoPerCapita) {
        response = planejamento?.quantidadeMaterialDiversoPerCapita;
      }
    }

    return response;
  }

  calcularTotalMaterialDiverso(planejamento: Planejamento): number {
    let response = 0;

    if (planejamento?.quantidadeMaterialDiverso > 0) {
      response = planejamento?.quantidadeMaterialDiverso;
    }

    if (planejamento?.quantidadeMaterialDiversoPerCapita > 0) {
      response = planejamento?.quantidadeMaterialDiversoPerCapita * planejamento.numeroComensais;
    }

    return response;
  }

  calcularPerCapitaProdutoItem(produtoItem: ProdutoItem, numeroComensais: number): number {
    let response = 0;

    if (produtoItem?.pesoRendimento > 0) {
      response = this.converterParaGrama(produtoItem.pesoRendimento, produtoItem.unidadePesoRendimento) / numeroComensais;
    }

    if (produtoItem?.pesoPerCapita > 0) {
      response = this.converterParaGrama(produtoItem.pesoPerCapita, produtoItem.unidadePesoPerCapita);
    }

    if (produtoItem?.numeroPorcoes > 0 && !produtoItem?.pesoRendimento && !produtoItem?.pesoPerCapita) {
      if (produtoItem?.fichaTecnica) {
        response = this.converterParaGrama(produtoItem.fichaTecnica.quantidadePorcao, produtoItem.fichaTecnica.unidadeQuantidadePorcao);
      }
    }

    if (numeroComensais > 0 && produtoItem?.quantidadeMaterialDiverso && produtoItem?.unidadeMaterialDiverso) {
      if (produtoItem?.materialDiverso) {
        response = produtoItem.quantidadeMaterialDiverso / numeroComensais;
      }
    }

    return response;
  }

  truncarValor(valor: number) {
    const valorTemp = Math.trunc(valor * 100) / 100;
    return valorTemp;
  }

  converterParaGrama(item: number, unidadeItem: string) {
    let response = 0;

    if (unidadeItem === 'kg' || unidadeItem === 'L') {
      response = item * 1000;
    } else {
      response = item;
    }

    return response;
  }

  toKj(kcal: number) {
    let response = kcal * 4.184;

    response = Math.trunc(response * 100) / 100;

    return response;
  }

  formatarDecimal(
    valor: any,
    decimalCount = 2,
    decimal = ',',
    thousands = '.'
  ) {
    if (valor === 0 || valor === null) {
      return '-';
    }
    let i;
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = valor < 0 ? '-' : '';

      // tslint:disable-next-line: radix
      i = parseInt(
        (valor = Math.abs(Number(valor) || 0).toFixed(decimalCount))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
          Math.abs(valor - i)
            .toFixed(decimalCount)
            .slice(2)
          : '')
      );
    } catch (e) {
      console.log(e);
    }
  }

  converterUnidadeMedida(value: any, unidadeMedida: any, liquido: boolean): any {

    if (value === '-') {
      value = 0;
    }

    if (unidadeMedida === 'L' || unidadeMedida === 'kg') {
      value = value * 1000;
      if (liquido === true) {
        if (value < 1000) {
          value = (Math.trunc(value * 100) / 100).toString();
          value = value.replace('.', ',');
          value = value + ' mL';
        } else {
          value = ((Math.trunc(value * 100) / 100) / 1000).toString();
          value = value.replace('.', ',');
          value = value + ' L';
        }
      } else {
        if (value < 1000) {
          value = (Math.trunc(value * 100) / 100).toString();
          value = value.replace('.', ',');
          value = value + ' g';
        } else {
          value = ((Math.trunc(value * 100) / 100) / 1000).toString();
          value = value.replace('.', ',');
          value = value + ' kg';
        }
      }
    } else {
      if (liquido === true) {
        if (value < 1000) {
          value = (Math.trunc(value * 100) / 100).toString();
          value = value.replace('.', ',');
          value = value + ' mL';
        } else {
          value = ((Math.trunc(value * 100) / 100) / 1000).toString();
          value = value.replace('.', ',');
          value = value + ' L';
        }
      } else {
        if (value < 1000) {
          value = (Math.trunc(value * 100) / 100).toString();
          value = value.replace('.', ',');
          value = value + ' g';
        } else {
          value = ((Math.trunc(value * 100) / 100) / 1000).toString();
          value = value.replace('.', ',');
          value = value + ' kg';
        }
      }
    }

    return value;
  }

  calcularUnidadeCompra(planejamento: Planejamento): number {
    let response = 0;

    if (planejamento.unidadeCompraId != null) {
      if (planejamento?.alimento) {
        let quantidadeUnidadeCompra = 1;
        const unidadeCompraSelecionada = planejamento.alimento?.unidadeCompra?.find(x => x.id === planejamento.unidadeCompraId);
        if (unidadeCompraSelecionada.unidadeQuantidadeReferencia != null) {
          if (unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'kg' || unidadeCompraSelecionada.unidadeQuantidadeReferencia === 'L') {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia * 1000;
          } else {
            quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
          }
        } else {
          quantidadeUnidadeCompra = unidadeCompraSelecionada.quantidadeReferencia;
        }

        response = planejamento.alimento.quantidadeEstoque / quantidadeUnidadeCompra;
      }

      if (planejamento?.materialDiverso) {
        const unidadeCompraSelecionada = planejamento.materialDiverso?.unidadeCompra?.find(x => x.id === planejamento.unidadeCompraId);
        response = planejamento.materialDiverso.quantidadeEstoque / unidadeCompraSelecionada.quantidadeReferencia;
      }
    }

    response = response > Math.round(response) ? Math.round(response) + 1 : response;

    return response;
  }

  calcularCampoQuantidadeEstoque(planejamento: Planejamento): number {
    planejamento.quantidadeEstoque = this.calcularUnidadeCompra(planejamento);
    return planejamento.quantidadeEstoque;
  }

  calcularCampoQuantidadeEstoqueFichaTecnica(planejamento: Planejamento): number {
    let response = 0;

    if (planejamento?.fichaTecnica) {
      response = planejamento.fichaTecnica.quantidadeEstoque / planejamento.fichaTecnica.quantidadePorcao;
    }

    return response;
  }

}
