const pageMenuClienteResponsavel = [
  {
    title: 'MENU CLIENTE',
    isTitle: true
  },
  {
    icon: 'fas fa-list',
    title: 'Cardápio',
    url: '/cardapio-cliente',
  },
];

export default pageMenuClienteResponsavel;
