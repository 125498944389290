const constants = {
    PERMISSOES: {
        SEM_ACESSO: 'semAcesso',
        SOMENTE_VISUALIZAR: 'somenteVisualizar',
        CONTROLE_TOTAL: 'controleTotal',
        ADMIN_MODULO: 'adminModulo',
    },
    MODULOS: {
        ALIMENTOS: 'alimentos',
        MATERIAIS_DIVERSOS: 'materiaisDiversos',
        FICHAS_TECNICAS: 'fichasTecnicas',
        PRODUTO: 'produto',
        CARDAPIO: 'cardapio',
        PEDIDO: 'pedido',
        MAPA_PRODUCAO: 'mapaProducao',
        MOVIMENTACOES: 'movimentacoes',
        INVENTARIO: 'inventario',
        ORDEM_SAIDA: 'ordemSaida',
        CONFIGURACOES: 'configuracoes',
        PAINEL_ADMIN: 'painelAdmin',
        COMPRAS: 'compras',
        EXECUCAO: 'execucao',
        EXPEDICAO: 'expedicao',
        FORNECEDORES: 'fornecedores',
        CLIENTES: 'clientes',
        TIPOS_CLASSES: 'tiposClasses',
        RECEITAS_CALCULADAS: 'receitasCalculadas',
        RELATORIOS: 'relatorios',
        FINANCEIRO_MOVIMENTACOES: 'movimentacaoFinanceira',
        FINANCEIRO_CATEGORIAS: 'categoriaCusto',
        FINANCEIRO_CENTROS_DE_CUSTO: 'centroDeCusto',

    },
    modulosPlanos: {
        moduloFinanceiroMovimentacoes: ['moduloFinanceiroMovimentacoes','visivel'],
        moduloFinanceiroCategorias: ['moduloFinanceiroCategorias','visivel'],
        moduloFinanceiroCentroCusto: ['moduloFinanceiroCentroCusto','visivel'],
        moduloAlimento: ['moduloAlimento','visivel'],
        moduloMaterialDiverso: ['moduloMaterialDiverso','visivel'],
        moduloProduto: ['moduloProduto','visivel'],
        moduloCardapio: ['moduloCardapio','visivel'],
        moduloPedidos: ['moduloPedidos','visivel'],
        moduloFornecedores: ['moduloFornecedores','visivel'],
        moduloClientes: ['moduloClientes','visivel'],
        moduloFichaTecnica: ['moduloFichaTecnica','visivel'],
        submoduloFichaTecnicaBanco: ['submoduloFichaTecnicaBanco','visivel'],
        submoduloFichaTecnicaExcel: ['submoduloFichaTecnicaExcel','visivel'],
        submoduloFichaTecnicaImpressoes: ['submoduloFichaTecnicaImpressoes','visivel'],
        moduloTiposClasses: ['moduloTiposClasses','visivel'],
        submoduloTiposClassesMotivo: ['submoduloTiposClassesMotivo','visivel'],
        submoduloTiposClassesGrupoClientes: ['submoduloTiposClassesGrupoClientes','visivel'],
        submoduloTiposClassesCategoriaItemCardapio: ['submoduloTiposClassesCategoriaItemCardapio','visivel'],
        moduloMapaProducao: ['moduloMapaProducao','visivel'],
        moduloReceitasCalculadas: ['moduloReceitasCalculadas','visivel'],
        moduloExecucao: ['moduloExecucao','visivel'],
        moduloExpedicao: ['moduloExpedicao','visivel'],
        moduloMovimentacao: ['moduloMovimentacao','visivel'],
        moduloInventario: ['moduloInventario','visivel'],
        moduloListaCompras: ['moduloListaCompras','visivel'],
        moduloGerarCompras: ['moduloGerarCompras','invisivel'],
        moduloRelatorioCompras: ['moduloRelatorioCompras','visivel'],
        moduloDashboard: ['moduloDashboard','visivel'],
    }
};

export default constants;