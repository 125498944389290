<div>
  <ul class="navbar-nav navbar-right">
    <li class="dropdown" ngbDropdown>
      <a href="javascript:;" ngbDropdownToggle  class="icon-notificacao" (click)="abrirDropDownNotificacao()">
        <em class="fas " [ngClass]="animacaoAtiva ? 'fa-bell' : 'fa-bell'" [@icone-animado]="iconeAnimado" (@icone-animado.done)="onEndAnimate($event)"></em>
        <span class="badge badge-icon">{{listaNotificacao.length}}</span>
      </a>

      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <div class="dropdown-header">
          <div class="m-0 text-center f-s-16 text-success">
            {{listaNotificacao.length}} <span
            *ngIf="listaNotificacao.length > 1; else templateQuantidadeNotificacao">Novas Notificações</span>
            <ng-template #templateQuantidadeNotificacao>
              <span>Nova Notificação</span>
            </ng-template>
          </div>
        </div>
        <div class="custom-scroll h-100">
          <ng-scrollbar>
            <ul class="notificacao">
              <ng-container *ngFor="let notificacao of listaNotificacao">
                <li class="cursor-point" *ngIf="listaNotificacao.length > 0"
                [ngClass]="{'unread': !notificacao.visualizado}">
                  <div class="d-flex justify-content-between align-items-center show-child-on-hover cursor-pointer">
                    <div class="d-flex flex-column flex-1" (click)="visualizarNotificacao(notificacao)">
                      <div class="f-s-12">
                        {{notificacao?.mensagem}}
                      </div>
                      <div class="f-s-10 text-muted mt-1">{{notificacao?.tempoPassouNotificacao}}</div>
                    </div>
                    <div class="show-on-hover-parent ">
                      <a (click)="excluirNotificacao(notificacao?.id)" href="javascript:;" class="text-muted f-s-14 "
                        title="delete"><em class="fas fa-trash-alt"></em></a>
                    </div>
                  </div>
                </li>
              </ng-container>
              <li *ngIf="listaNotificacao.length === 0">
                <div class="text-center">
                  Não existe notificações
                </div>
              </li>
            </ul>
          </ng-scrollbar>
        </div>
        <div class="dropdown-footer text-center">
          <button class="btn btn-white f-s-12" [disabled]="listaNotificacao.length === 0" (click)="marcarTodasNotificacoesComoVisualizada()">
            Marcar todas como visualizadas
          </button>
        </div>
      </div>
    </li>
  </ul>
</div>
