import { Planejamento } from './planejamento';

export class TipoRefeicao {
  id: string;
  nome: string;
  dataCadastro: Date;
  dataAtualizacao: Date;
  empresaId: string;
  posicao: number;

  planejamento: Planejamento[];
}
