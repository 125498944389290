import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Planejamento } from 'src/app/shared/models/planejamento';
import { FiltroPlanejamento } from 'src/app/shared/models/filtros/filtroPlanejamento';
import { Observable } from 'rxjs';
import { NdpCal } from 'src/app/modules/mapa-producao/models/ndpCal';
import { LocalStorageObject } from './localStorageObject.service';
import { TipoRefeicaoPlanejamento } from 'src/app/modules/mapa-producao/models/tipoRefeicaoPlanejamento';
import { PlanejamentoCusto } from 'src/app/modules/mapa-producao/models/planejamentoCusto';

@Injectable({
  providedIn: 'root'
})
export class PlanejamentoService extends BaseService {

  constructor(
    private readonly http: HttpClient,
    private readonly localStorageObject: LocalStorageObject
    ) {
    super();
  }

  obterLista(token: string) {
    const filtroPlanejamento = new FiltroPlanejamento();
    filtroPlanejamento.nome = token;
    filtroPlanejamento.empresaId = this.localStorageObject?.empresaSelecionada?.id;
    return this.http.post<Planejamento[]>(this.urlService + 'planejamento/obterLista', filtroPlanejamento, super.ObterAuthHeaderJson());
  }

  obterPlanejamento(filtroPlanejamento: FiltroPlanejamento): Observable<Planejamento[]> {
    filtroPlanejamento.empresaId = this.localStorageObject?.empresaSelecionada?.id;
    return this.http.post<Planejamento[]>(this.urlService + 'planejamento/obterPlanejamento', filtroPlanejamento, super.ObterAuthHeaderJson());
  }

  calcularIngredientesFichaTecnicaPlanejamentoDia(filtroPlanejamento: FiltroPlanejamento): Observable<Planejamento[]> {
    filtroPlanejamento.empresaId = this.localStorageObject?.empresaSelecionada?.id;
    return this.http.post<Planejamento[]>(this.urlService + 'planejamento/calcularIngredientesFichaTecnicaPlanejamentoDia', filtroPlanejamento, super.ObterAuthHeaderJson());
  }

  cadastrarListaPlanejamento(listaPlanejamento: Planejamento[]): Observable<Planejamento[]> {
    return this.http.post<Planejamento[]>(this.urlService + 'planejamento/cadastrarListaPlanejamento', listaPlanejamento, super.ObterAuthHeaderJson());
  }

  editarListaPlanejamento(listaPlanejamento: Planejamento[]): Observable<Planejamento[]> {
    return this.http.post<Planejamento[]>(this.urlService + 'planejamento/editarListaPlanejamento', listaPlanejamento, super.ObterAuthHeaderJson());
  }

  obterNutrientesPlanejamento(filtroPlanejamento: FiltroPlanejamento): Observable<Planejamento[]> {
    return this.http.post<Planejamento[]>(this.urlService + 'planejamento/obterNutrientesPlanejamento', filtroPlanejamento, super.ObterAuthHeaderJson());
  }

  duplicarPlanejamentos(filtroPlanejamento: FiltroPlanejamento): Observable<Planejamento[]> {
    filtroPlanejamento.empresaId = this.localStorageObject?.empresaSelecionada?.id;
    return this.http.post<Planejamento[]>(this.urlService + 'planejamento/duplicarPlanejamentos', filtroPlanejamento, super.ObterAuthHeaderJson());
  }

  duplicarRangePlanejamentos(filtroPlanejamento: FiltroPlanejamento): Observable<Planejamento[]> {
    filtroPlanejamento.empresaId = this.localStorageObject?.empresaSelecionada?.id;
    return this.http.post<Planejamento[]>(this.urlService + 'planejamento/duplicarRangePlanejamentos', filtroPlanejamento, super.ObterAuthHeaderJson());
  }

  excluirPlanejamentosDia(filtroPlanejamento: FiltroPlanejamento): Observable<Planejamento[]> {
    filtroPlanejamento.empresaId = this.localStorageObject?.empresaSelecionada?.id;
    return this.http.post<Planejamento[]>(this.urlService + 'planejamento/excluirPlanejamentosDia', filtroPlanejamento, super.ObterAuthHeaderJson());
  }

  calcularNdpCalRefeicao(filtroPlanejamento: FiltroPlanejamento): Observable<NdpCal> {
    filtroPlanejamento.empresaId = this.localStorageObject?.empresaSelecionada?.id;
    return this.http.post<NdpCal>(this.urlService + 'planejamento/calcularNdpCalRefeicao', filtroPlanejamento, super.ObterAuthHeaderJson());
  }

  obterPlanejamentoMapaProducao(filtroPlanejamento: FiltroPlanejamento): Observable<TipoRefeicaoPlanejamento[]> {
    filtroPlanejamento.empresaId = this.localStorageObject?.empresaSelecionada?.id;
    return this.http.post<TipoRefeicaoPlanejamento[]>(this.urlService + 'planejamento/obterPlanejamentoMapaProducao', filtroPlanejamento, super.ObterAuthHeaderJson());
  }

  obterPlanejamentoMapaProducaoCusto(filtroPlanejamento: FiltroPlanejamento): Observable<PlanejamentoCusto> {
    filtroPlanejamento.empresaId = this.localStorageObject?.empresaSelecionada?.id;
    return this.http.post<PlanejamentoCusto>(this.urlService + 'planejamento/obterPlanejamentoMapaProducaoCusto', filtroPlanejamento, super.ObterAuthHeaderJson());
  }

}
