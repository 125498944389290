import { PlanoEmpresaItem } from './planoEmpresaItem';
import { Plano } from './plano';

export class PlanoEmpresa {
  id: string;
  excluido: boolean;
  dataCadastro: Date;
  dataAtualizacao: Date;
  cancelado: boolean;
  dataInicio: Date;
  dataTermino: Date;
  planoId: string;
  plano: Plano;
  planoEmpresaItem: PlanoEmpresaItem[];
}
