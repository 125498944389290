import { animate, style, trigger, transition, state } from '@angular/animations';
import { Component, Input, Output, EventEmitter, ElementRef, HostListener, ViewChild, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import pageSettings from '../config/page-settings';
import { NavigationEnd, Router } from '@angular/router';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { UsuarioEmpresa } from 'src/app/shared/models/usuarioEmpresa';
import { Usuario } from 'src/app/shared/models/usuario';
import constants from '../config/constants';
import { ModalBloqueioPlanoComponent } from 'src/app/shared/modal-bloqueio-plano/modal-bloqueio-plano.component';
import { ModalBloqueioPlanoService } from 'src/app/shared/modal-bloqueio-plano/modal-bloqueio-plano.service';
import pageMenus from '../config/page-menus';
import pageMenuAdmin from '../config/page-menu-admin';
import pageMenuFinanceiro from '../config/page-menu-financeiro';
import { LocalStorageObject } from '../services/localStorageObject.service';
import { PermissoesService } from '../auth/permissoes.service';
import { TipoUsuarioEnum } from 'src/app/shared/models/enum/tipoUsuario.enum';
import pageMenuClienteResponsavel from '../config/page-menu-cliente-responsavel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('expand', style({ height: '*', overflow: 'hidden', display: 'block' })),
      state('collapse', style({ height: '0px', overflow: 'hidden', display: 'block' })),
      state('active', style({ height: '*', overflow: 'hidden', display: 'block' })),
      transition('expand <=> collapse', animate(100)),
      transition('active => collapse', animate(100))
    ])
  ]
})

export class SidebarComponent implements OnInit, AfterViewChecked, OnDestroy {

  @BlockUI() blockUI: NgBlockUI;

  @ViewChild(ModalBloqueioPlanoComponent, { static: false }) 'modalBloqueioPlanoComponent': ModalBloqueioPlanoComponent;
  @ViewChild('sidebarScrollbar', { static: true }) sidebarScrollbar: ElementRef;
  @Output() toggleSidebarMinified = new EventEmitter<boolean>();
  @Output() hideMobileSidebar = new EventEmitter<boolean>();
  @Input() pageSidebarTransparent;
  @Input() pageSidebarMinified;

  usuarioEmpresas: Array<UsuarioEmpresa>;
  usuario: Usuario;
  modulos: any;
  navProfileState = 'collapse';
  isExpand: boolean;
  isCaretActive: boolean;

  menus: any;
  pageSettings = pageSettings;

  mobileMode: boolean;
  desktopMode: boolean;
  scrollTop: number;
  token: string;
  url: string;
  subscriptionRouter: Subscription;
  subscriptionEmpresaSelecionada: Subscription;

  constructor(
    private readonly eRef: ElementRef,
    private readonly router: Router,
    private readonly modalBloqueioPlanoService: ModalBloqueioPlanoService,
    private readonly localStorageObject: LocalStorageObject,
    private readonly permissoesService: PermissoesService
    ) {
    this.isExpand = false;
    this.usuario = this.localStorageObject.usuario;
    this.usuarioEmpresas = this.usuario.usuarioEmpresa;
    this.token = this.localStorageObject.token;
    this.modulos = constants.MODULOS;

    if (window.innerWidth <= 767) {
      this.mobileMode = true;
      this.desktopMode = false;
    } else {
      this.mobileMode = false;
      this.desktopMode = true;
    }
  }

  ngOnInit() {
    this.validarMenu(null);
    this.subscribes();
  }

  ngOnDestroy() {
    if (this.subscriptionRouter) {
      this.subscriptionRouter.unsubscribe();
    }
    if (this.subscriptionEmpresaSelecionada) {
      this.subscriptionEmpresaSelecionada.unsubscribe();
    }
  }

  ngAfterViewChecked() {
    if (typeof (Storage) !== 'undefined' && localStorage.sidebarScroll) {
      if (this.sidebarScrollbar && this.sidebarScrollbar.nativeElement) {
        this.sidebarScrollbar.nativeElement.scrollTop = localStorage.sidebarScroll;
      }
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.hideMobileSidebar.emit(true);
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    this.scrollTop = (this.pageSettings.pageSidebarMinified) ? event.srcElement.scrollTop + 40 : 0;
    if (typeof (Storage) !== 'undefined') {
      localStorage.setItem('sidebarScroll', event.srcElement.scrollTop);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 767) {
      this.mobileMode = true;
      this.desktopMode = false;
    } else {
      this.mobileMode = false;
      this.desktopMode = true;
    }
  }

  subscribes(): void {
    this.subscriptionRouter = this.router.events.subscribe(response => {
      if (response instanceof NavigationEnd) {
        const route = response.url.substring(0, 6);
        this.validarMenu(route);
      }
    });

    this.subscriptionEmpresaSelecionada = this.localStorageObject.notificadorAlterarEmpresa.subscribe(response => {
      const rotaAtual = this.router.url;
      this.validarMenu(rotaAtual);
    });
  }

  validarMenu(route: string): void {
    if (route === '/admin') {
      this.menus = pageMenuAdmin;
    } else {
      if (this.localStorageObject?.empresaSelecionada && this.localStorageObject?.empresaSelecionada?.id !== '') {
        if (this.localStorageObject?.empresaSelecionada?.tipo === 0) {
          this.menus = pageMenus;
        }

        if (this.localStorageObject?.empresaSelecionada?.tipo === 1) {
          this.menus = pageMenuFinanceiro;
        }
      } else {
        if (this.localStorageObject?.usuario?.tipoUsuario === TipoUsuarioEnum.ClienteResponsavel) {
          this.menus = pageMenuClienteResponsavel;
        }
      }
    }
  }

  toggleNavProfile(): void {
    if (this.navProfileState === 'collapse') {
      this.navProfileState = 'expand';
    } else {
      this.navProfileState = 'collapse';
    }
  }

  toggleMinified(): void {
    this.toggleSidebarMinified.emit(true);
    this.scrollTop = 0;
    this.isExpand = !this.isExpand;

  }

  expandCollapseSubmenu(currentMenu, allMenu, active): void {
    for (const menu of allMenu) {
      if (menu !== currentMenu) {
        menu.state = 'collapse';
      }
    }
    if (currentMenu.state === 'expand' || (active.isActive && !currentMenu.state)) {
      currentMenu.state = 'collapse';
    } else {
      currentMenu.state = 'expand';
    }
  }

  usuarioLogado(): boolean {
    this.token = this.localStorageObject.token;
    if (!this.token) {
      return false;
    }
    return true;
  }

  navigateMenu(menu){
    if (this.podeExibirModuloPlano(menu.moduloPlano)) {
      this.router.navigate([menu.url]);
    }
    else{
      this.abrirModalModuloBloqueadoPlano(menu);
    }
  }

  podeExibirGrupoModulosPlano(submenu): boolean {
    // Verificando se tem permissao em pelo menos um dos itens
    let podeExibir = false;


    for (let i = 0; i < submenu.length; i++) {
      const subItem = submenu[i];

      if (this.permissoesService.podeExibirModuloPlano(subItem.moduloPlano)) {
        podeExibir = true;
        break;
      }
    }

    return podeExibir;
  }

  podeExibirModuloPlano(moduloPlano: string[]): boolean {
    return this.permissoesService.podeExibirModuloPlano(moduloPlano);
  }

  isModuloPlanoVisivel(moduloPlano: string[]): boolean {
    return this.permissoesService.isModuloPlanoVisivel(moduloPlano);
  }

  abrirModalModuloBloqueadoPlano(menu) {
    if (menu.moduloPlano) {
      this.modalBloqueioPlanoService.notificar({ modulo: menu.moduloPlano });
    }
    else {
      const moduloPlano = 'modulo' + menu.title;
      this.modalBloqueioPlanoService.notificar({ modulo: moduloPlano });
    }
  }

  podeExibirModulo(moduloId): boolean {
    return this.permissoesService.podeExibirModulo(moduloId);
  }

  podeExibirGrupoModulos(submenu): boolean {
    // Verificando se tem permissao em pelo menos um dos itens
    let podeExibir = false;


    for (let i = 0; i < submenu.length; i++) {
      const subItem = submenu[i];

      if (this.permissoesService.podeExibirModulo(subItem.moduloId)) {
        podeExibir = true;
        break;
      }
    }


    return podeExibir;
  }

  logoEmpresa(): string {
    let retorno = '';
    const empresaSelecionada = this.localStorageObject.empresaSelecionada;

    if (empresaSelecionada && empresaSelecionada.urlFoto && empresaSelecionada.urlFoto.length > 0 && empresaSelecionada.planoEmpresa.plano.impressaoLogo) {
      retorno = empresaSelecionada.urlFoto;
    } else {
      retorno = '/assets/img/avatar-empresa_1.jpg';
    }

    return retorno;
  }
}



