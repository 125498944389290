import { Injectable, EventEmitter } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { FiltroNotificacao } from 'src/app/shared/models/filtros/filtroNotificacao';
import { Observable } from 'rxjs';
import { Notificacao } from 'src/app/shared/models/notificacao';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService extends BaseService {

  notificador = new EventEmitter();
  notificadorLoop = new EventEmitter();
  notificadorComanda = new EventEmitter();
  notificadorComandaFechada = new EventEmitter();

  constructor(private http: HttpClient) {
    super();
  }

  notificar() {
    this.notificador.emit();
  }

  notificarLoop() {
    this.notificadorLoop.emit();
  }

  notificarComanda() {
    this.notificadorComanda.emit();
  }

  notificarComandaFechada() {
    this.notificadorComandaFechada.emit();
  }

  obterLista(filtroNotificacao: FiltroNotificacao): Observable<Notificacao[]> {
    return this.http.post<Notificacao[]>(this.urlService + 'notificacao/obterLista', filtroNotificacao, super.ObterAuthHeaderJson());
  }

  inserir(notificacao: Notificacao): Observable<Notificacao> {
    return this.http.post<Notificacao>(this.urlService + 'notificacao/inserir', notificacao, super.ObterAuthHeaderJson());
  }

  alterar(notificacao: Notificacao): Observable<Notificacao> {
    return this.http.put<Notificacao>(this.urlService + 'notificacao/alterar', notificacao, super.ObterAuthHeaderJson());
  }

  excluir(id: string): Observable<Notificacao> {
    return this.http.delete<Notificacao>(this.urlService + 'notificacao/excluir?id=' + id, super.ObterAuthHeaderJson());
  }

  marcarTodasNotificacoesComoVisualizada(filtroNotificacao: FiltroNotificacao): Observable<Notificacao[]> {
    return this.http.post<Notificacao[]>(this.urlService + 'notificacao/marcarTodasNotificacoesComoVisualizada',
      filtroNotificacao, super.ObterAuthHeaderJson());
  }

}
